import React from 'react'
import connectToStore from '../connect';
/**
* @author
* @function State
**/

const State = ({ store, to }) => {

    let ss = {};
    store.stats.map(({ key, value }) => ss[key] = value);

    let states = [
        {
            key: "En attente",
            nbr: ss["En attente"] || 0
        },
        {
            key: "En Préparation",
            nbr: ss["En Préparation"] || 0
        },
        {
            key: "Prêt",
            nbr: ss["Prêt"] || 0
        },
        {
            key: "Livré",
            nbr: ss["Livré"] || 0
        }
    ];

    return (
        <div className="State">
            <div className="container">
                {states.map(val => (
                    <div className={'State-item ' + (val.nbr ? "State-item-active" : "")} key={val.key} onClick={e => to("/app/" + store.user.role + "/commande/list")}>
                        <div className="State-item-nbr">{val.nbr}</div>
                        <div className="State-item-titre">{val.key}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}


export default connectToStore(State)