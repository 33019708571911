import React from 'react'
import Prix from './Prix';

/**
* @author
* @function PrixCmd
**/

const PrixCmd = ({ cmd, priceOnly }) => {
    let list = (cmd || {}).list || [];
    let total = list.reduce((total, item) => {
        let totalsup = 0;
        (item.supplementspayants || [])
            .filter(sup => sup && sup.checked && sup.prix > 0)
            .map(sup => {
                totalsup += sup.prix;
            });
        return total + (item.prix_vente_u_ttc + totalsup) * (item.qte || 1);
    }, 0)
    let remise = 0;
    if (cmd.remise_type && cmd.remise_value) {
        switch (cmd.remise_type) {
            case "%":
                remise = (cmd.remise_value * total / 100);
                total -= (cmd.remise_value * total / 100)
                break;
            case "DT":
                remise = cmd.remise_value;;
                total -= cmd.remise_value;
                break;
        }
    }
    cmd.total_final = total;
    if (priceOnly) return <div style={{ textAlign: 'right' }}><Prix value={total} /></div>
    return (
        <>
            {remise > 0 ? (
                <div className="print-item" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                    <span style={{ flex: 1 }}>Remise :</span>
                    <Prix value={remise} />
                </div>
            ) : null}
            <div className="print-item" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                <span style={{ flex: 1 }}>Total :</span>
                <Prix value={total} />
            </div>
        </>
    )
}


export default PrixCmd