import React, { useState, useEffect } from 'react';
import connectToStore from '../connect';
import Layout from './../com/Layout';
import PrixCmd from './../com/PrixCmd';
import Dialog from './../com/Dialog';
import CommandePrintViewCmd from './CommandePrintViewCmd';

/**
 * @author
 * @function PublicMesCmds
 **/

const PublicMesCmds = (props) => {
  var state = useState({
    filter: { ...(props.filter || {}), uid: props.store.publicUser.uid },
  }).asPagination('loadMesCommandes');
  var { Table, loadData } = state;
  useEffect(loadData, []);
  const cols = [
    { name: 'id', titre: 'Com. N°' },
    { titre: 'Date', render: (row) => row.createAtF },
    { titre: 'Tel', render: (row) => row.data.client_tel },
    { titre: 'Adresse', render: (row) => row.data.adresse_livraison },
    { name: 'etat_str', titre: 'Etat' },
    {
      name: 'total_final',
      titre: 'Total TTC',
      thStyle: { textAlign: 'right' },
      render: (row) => <PrixCmd cmd={row.data} priceOnly={true} />,
    },
  ];
  var viewDialog = ''.$obj();
  const onClickAction = (name) => (row) => {
    switch (name) {
      case 'edit':
        row.select();
        viewDialog.open();
        break;
    }
  };

  return (
    <Layout>
      <hr />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h1 style={{ flex: 1 }}>Mes commandes</h1>
        <span>
          Total des Points dans votre compte est :{' '}
          <b>
            {(props.store &&
              props.store.publicUser &&
              props.store.publicUser.points) ||
              0}
          </b>
          {' point(s)'}
        </span>
      </div>
      <Table
        cols={cols}
        actions={[{ name: 'eye', onClick: onClickAction('edit') }]}
        noDataMessage="Aucun commande trouvé"
      />
      <Dialog
        modal={viewDialog}
        titre="Commande"
        actions={[{ titre: 'Fermer', onClick: (dialog) => dialog.close() }]}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', minWidth: 350 }}
        >
          <CommandePrintViewCmd
            logo={props.store.data.config.logo}
            item={state.current && state.current.data}
          />
        </div>
      </Dialog>
    </Layout>
  );
};

export default connectToStore(PublicMesCmds);
