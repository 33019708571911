import React, { useState } from 'react'
import connectToStore from '../connect';
import { Modal, Button } from 'react-bootstrap';
import Loading from './Loading';

/**
* @author
* @function Dialog
**/

function Dialog(props) {

    let [dialogState, setDialogState] = useState({ visible: false });


    let modal = {
        open: () => {
            setDialogState({ ...dialogState, visible: true, loading: false })
        },
        close: () => setDialogState({ ...dialogState, visible: false, loading: false }),
        loading: val => setDialogState({ ...dialogState, loading: val }),
        toggle: () => setDialogState({ ...dialogState, visible: !dialogState.visible, loading: false }),
        set: obj => setDialogState({ ...dialogState, ...(obj || {}) }),
    }

    if (props.modal) {
        for (var x in modal) {
            props.modal[x] = modal[x];
        }
        if (props.modal.$emit) props.modal.$emit("init")
    }



    const handleClose = () => {
        setDialogState({ ...dialogState, visible: false })
    }

    let incprops = props.incprops || {};
    let actions = props.actions || [];

    return (
        <Modal show={dialogState.visible} onHide={handleClose} {...incprops}>
            {dialogState.loading ? <Loading /> : null}
            {(props.titre || props.title) ? (
                <Modal.Header closeButton >
                    <Modal.Title>{props.titre || props.title}</Modal.Title>
                </Modal.Header>
            ) : null}
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {actions.length ? (
                <Modal.Footer>
                    {actions.map((action, i) => (
                        <Button key={i} variant={action.primary ? 'primary' : 'secondary'} onClick={e => action.onClick ? action.onClick(modal) : null}>
                            {action.titre || action.title}
                        </Button>
                    ))}
                </Modal.Footer>
            ) : null}

        </Modal>
    )
}



export default Dialog