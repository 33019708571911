import React, { useEffect, useState } from 'react';
import Dialog from './Dialog';
import connectToStore from '../connect';
import CommandeShow from './CommandeShow';
import CommandeShowServeur from './CommandeShowServeur';
import Crud from './Crud';
import CommandePrintViewCmd from '../page/CommandePrintViewCmd';
import PrixCmd from './PrixCmd';
import FInput from './FInput';
import Prix from './Prix';

/**
 * @author
 * @function CommandeList
 **/

Date.prototype.toJSON1 = function () {
  if (!this.getFullYear()) return null;
  return (
    this.getFullYear() +
    '-' +
    ((this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1)) +
    '-' +
    (this.getDate() < 10 ? '0' : '') +
    this.getDate()
  );
};

const CommandeList = (props) => {
  var state = useState({
    filter: {
      ...props.filter,
      periode: {
        value: {
          periode: 'p1',
          de: new Date().toJSON1().split('T')[0],
          a: new Date().toJSON1().split('T')[0],
        },
        type: 'periode',
      },
    },
  }).asPagination('loadCommandes');
  state.filter.view = props.view;
  if (props.store.user.role === 'serveur') {
    state.filter.serveur = props.store.user.id;
  }

  var { Table, loadData } = state;

  var deleteDialog = ''.$obj();
  var noteDialog = ''.$obj();
  var viewDialog = ''.$obj();
  var genFactureDialog = ''.$obj();
  var showFactureDialog = ''.$obj();
  deleteDialog.$on('open', () => console.log('Dialog Opened'));

  useEffect(loadData, []);
  if (props.actions) props.actions.back = () => props.to('/app/');

  const onClickAction = (name) => (row) => {
    switch (name) {
      case 'edit':
        // props.to(`/app/commande/edit/${row.id}`)
        row.select();
        viewDialog.open();
        break;
      case 'delete':
        row.select();
        deleteDialog.open();
        break;
      case 'print':
        [].print(
          <CommandePrintViewCmd
            logo={props.store.data.config.logo}
            item={row}
          />
        );
        // props.to(`/app/commande/print/${row.id}`)
        break;
      case 'facture':
        row.select();
        if (!row.facture) {
          genFactureDialog.open();
        } else {
          showFactureDialog.open();
        }
        break;
    }
  };

  const generateFacture = (dialog) => {
    dialog.loading(true);
    props.store
      .api('generateFacture', { cmd: state.current })
      .then(({ cmd }) => {
        state.current.facture = cmd.facture;
        state.set();
        showFactureDialog.open();
        loadData();
        dialog.loading(false);
        dialog.close();
      });
  };

  const DeleteCurrentRow = (dialog) => {
    dialog.loading(true);
    setTimeout(dialog.close, 1000);
  };

  if (props.view === 'poste') {
    return (
      <div>
        <div className="listcommandeshowposte">
          {props.store.activecommandes
            .filter((item) => !item.tablette)
            .map((item, i) => (
              <CommandeShow
                key={item.id}
                index={i}
                item={item}
                state={state}
                idType={props.store.user.id_type}
              />
            ))}
        </div>
      </div>
    );
  }

  if (props.view === 'serveur') {
    return (
      <div>
        <div className="listcommandeshowposte">
          {props.store.activecommandes.map((item, i) => (
            <CommandeShowServeur
              key={item.id}
              index={i}
              item={item}
              state={state}
            />
          ))}
        </div>
      </div>
    );
  }

  if (props.view === 'caissier') {
    return (
      <div>
        <div className="listcommandeshowposte">
          {props.store.activecommandes.map((item, i) => (
            <CommandeShowServeur
              key={item.id}
              index={i}
              item={item}
              state={state}
            />
          ))}
        </div>
      </div>
    );
  }

  if (props.view === 'resp') {
    let filtredlist = JSON.parse(JSON.stringify(props.store.activecommandes));
    filtredlist = filtredlist.filter((cmd) => {
      if (state.filter.etage && state.filter.etage.value != '') {
        if (state.filter.etage.value + '' !== '' + cmd.table.id_etage)
          return false;
      }

      if (state.filter.serveur && state.filter.serveur.value != '') {
        if (cmd.type_user != 'Serveur') return false;
        if (state.filter.serveur.value + '' !== '' + cmd.id_user) return false;
      }

      if (state.filter.caissier && state.filter.caissier.value != '') {
        if (cmd.type_user != 'Caissier') return false;
        if (state.filter.caissier.value + '' !== '' + cmd.id_user) return false;
      }

      if (
        state.filter.typepreparateur &&
        state.filter.typepreparateur.value != ''
      ) {
        cmd.list = cmd.list.filter(
          (elm) =>
            elm.id_typepreparateur + '' ==
            '' + state.filter.typepreparateur.value
        );
        if (!cmd.list.length) return false;
      }

      return true;
    });

    return (
      <div>
        <div
          style={{
            display: 'inline-grid',
            gridGap: 10,
            gridAutoFlow: 'column',
            paddingLeft: 10,
          }}
        >
          <FInput
            type="select"
            label="Etage"
            options={props.store.data.etages.map(({ id, titre }) => ({
              value: id,
              label: titre,
            }))}
            {...state.bind('filter.etage')}
          />
          {['responsable-front', 'admin'].includes(props.store.user.role) ? (
            <FInput
              type="select"
              label="Serveur"
              options={props.store.data.serveurs.map(({ id, nom }) => ({
                value: id,
                label: nom,
              }))}
              {...state.bind('filter.serveur')}
            />
          ) : null}
          {['responsable-back', 'admin'].includes(props.store.user.role) ? (
            <FInput
              type="select"
              label="Type Preparateur"
              options={props.store.data.typepreparateurs.map(
                ({ id, titre }) => ({ value: id, label: titre })
              )}
              {...state.bind('filter.typepreparateur')}
            />
          ) : null}
          <FInput
            type="select"
            label="Caissier"
            options={props.store.data.caissiers.map(({ id, nom }) => ({
              value: id,
              label: nom,
            }))}
            {...state.bind('filter.caissier')}
          />
        </div>

        <div className="listcommandeshowposte">
          {filtredlist.map((item, i) => (
            <CommandeShowServeur
              key={item.id}
              index={i}
              item={item}
              state={state}
            />
          ))}
        </div>
      </div>
    );
  }

  const cols = [
    { name: 'num', titre: 'Com. N°' },
    { name: 'dateformat', titre: 'Date' },
    {
      name: 'table.titre',
      titre: 'Table',
      render: (row) => row.table && row.table.titre,
    },
    { name: 'name_user', titre: 'Utilisateur' },
    { name: 'type_user', titre: 'Type' },
    {
      name: 'state',
      titre: 'Etat',
      render: (row) => (
        <div
          style={{
            color: row.etat == 1 ? 'green' : row.etat == 2 ? 'red' : '#000000',
          }}
        >
          {row.state}
        </div>
      ),
    },
    {
      name: 'encaissement',
      titre: 'Encaissement',
      render: (row) => (
        <div style={{ color: row.encaissement ? 'green' : 'red' }}>
          {' '}
          {row.encaissement ? 'Oui' : 'Non'}{' '}
        </div>
      ),
    },
    { name: 'temps', titre: 'Temps' },
  ];

  cols.push({
    name: 'remise_value',
    titre: 'Remise',
    render: (row) => (
      <div style={{ textAlign: 'right' }}>
        {row.remise_value ? row.remise_value + ' ' + row.remise_type : '--'}
        {row.remise_value ? (
          <button
            className="btn btn-ms btn-dark"
            style={{ marginLeft: 10, zoom: 0.5 }}
            onClick={(e) => {
              row.select();
              noteDialog.open();
            }}
          >
            <i className="fa fa-eye"></i>
          </button>
        ) : null}
      </div>
    ),
  });
  cols.push({
    name: 'total',
    titre: 'Total',
    render: (row) => <PrixCmd cmd={row} priceOnly={true} />,
  });

  return (
    <div>
      <div
        style={{ display: 'inline-grid', gridGap: 10, gridAutoFlow: 'column' }}
      >
        {[
          'admin',
          'caissier',
          'responsable-front',
          'responsable-back',
        ].includes(props.store.user.role) ? (
          <FInput
            type="periode"
            label="Date"
            {...state.bind('filter.periode', { onChange: () => loadData() })}
          />
        ) : null}
        {props.store.user.role === 'serveur' ? null : (
          <FInput
            type="select"
            label="Serveur"
            options={props.store.data.serveurs.map(({ id, nom }) => ({
              value: id,
              label: nom,
            }))}
            {...state.bind('filter.serveur', { onChange: () => loadData() })}
          />
        )}
        {props.store.user.role === 'serveur' ||
        props.store.user.role === 'poste' ? null : (
          <FInput
            type="select"
            label="Caissier"
            options={props.store.data.caissiers.map(({ id, nom }) => ({
              value: id,
              label: nom,
            }))}
            {...state.bind('filter.caissier', { onChange: () => loadData() })}
          />
        )}
        {[
          'serveur',
          'admin',
          'caissier',
          'responsable-front',
          'responsable-back',
        ].includes(props.store.user.role) ? (
          <>
            <FInput
              type="select"
              label="Paiement"
              options={['Payé', 'Non Payé']}
              {...state.bind('filter.paiement', { onChange: () => loadData() })}
            />
            <FInput
              type="select"
              label="Encaissement"
              options={['Encaissé', 'Non Encaissé']}
              {...state.bind('filter.encaissement', {
                onChange: () => loadData(),
              })}
            />
          </>
        ) : null}
      </div>
      <Table
        cols={cols}
        actions={[
          { name: 'print', onClick: onClickAction('print') },
          { name: 'eye', onClick: onClickAction('edit') },
          {
            render: (row, i) => {
              if (
                !row.facture &&
                ![
                  'responsable-front',
                  'responsable-back',
                  'caissier',
                  'admin',
                ].includes(props.store.user.role)
              )
                return null;
              return (
                <button
                  key={i}
                  className={
                    'btn btn-ms ' + (row.facture ? 'btn-success' : 'btn-dark')
                  }
                  style={{ margin: '0 3px' }}
                  onClick={(e) => onClickAction('facture')(row)}
                >
                  <i className="fa fa-file"></i>{' '}
                  {row.facture ? 'Fa. N°: ' + row.facture.id : 'Facture'}
                </button>
              );
            },
          },
        ]}
        footerRight={[
          ['poste', 'responsable-front', 'responsable-back'].includes(
            props.store.user.role
          ) ? null : (
            <table
              className="table table-bordered"
              style={{ width: 250 }}
              key="1"
            >
              <tbody>
                <tr>
                  <th>Total</th>
                  <th style={{ textAlign: 'right' }}>
                    {' '}
                    <Prix value={(state.totals && state.totals.total) || 0} />
                  </th>
                </tr>
              </tbody>
            </table>
          ),
        ]}
      />
      <Dialog
        modal={viewDialog}
        titre="Commande"
        actions={[{ titre: 'Fermer', onClick: (dialog) => dialog.close() }]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CommandePrintViewCmd
            logo={props.store.data.config.logo}
            item={state.current}
          />
        </div>
      </Dialog>
      <Dialog
        modal={deleteDialog}
        titre="Supprimer commande"
        actions={[
          { titre: 'Annuler', onClick: (dialog) => dialog.close() },
          {
            titre: 'OK',
            primary: true,
            onClick: (dialog) => DeleteCurrentRow(dialog),
          },
        ]}
      >
        <p>Voulez-vous vraiment supprimer cette commande ?</p>
      </Dialog>
      <Dialog
        modal={noteDialog}
        titre="Note"
        actions={[{ titre: 'Fermer', onClick: (dialog) => dialog.close() }]}
      >
        <pre>{state.current && state.current.remise_note}</pre>
      </Dialog>
      <Dialog
        modal={genFactureDialog}
        titre="Générer une facture"
        actions={[
          { titre: 'Annuler', onClick: (dialog) => dialog.close() },
          {
            titre: 'Générer une facture',
            onClick: (dialog) => generateFacture(dialog),
          },
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p>Voulez-vous vraiment générer une facture pour cette commande ?</p>
        </div>
      </Dialog>
      <Dialog
        modal={showFactureDialog}
        titre={
          'Facture N°:' +
          (state.current && state.current.facture
            ? state.current.facture.id
            : '--')
        }
        actions={[
          { titre: 'Fermer', onClick: (dialog) => dialog.close() },
          {
            titre: 'Imprimer',
            onClick: () => {
              [].print(
                <CommandePrintViewCmd
                  logo={props.store.data.config.logo}
                  item={state.current}
                  facture={state.current && state.current.facture}
                />
              );
            },
          },
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CommandePrintViewCmd
            logo={props.store.data.config.logo}
            item={state.current}
            facture={state.current && state.current.facture}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default connectToStore(CommandeList);
