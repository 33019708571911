import React, { useState } from 'react';
import connectToStore from '../connect';
import Layout from '../com/Layout';
import CommandeShowServeur from '../com/CommandeShowServeur';
/**
 * @author
 * @function InfoScreen
 **/

const InfoScreen = (props) => {
  var state = useState({}).toState();
  let list = props.store.commandes
    .filter((item) => item.statepublic == 'Livré')
    .filter((item) => !item.table.id);

  // return (
  //     <div>
  //         <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
  //             <img style={{ height: 100 }} src={props.store.data.config.logo.getUrl()} />
  //         </div>
  //         <div style={{
  //             flex: 1,
  //             background: '#5dbb4b',
  //             display: 'flex',
  //             alignItems: 'center',
  //             justifyContent: 'center',
  //             fontSize: 400,
  //             fontWeight: 'bold',
  //             color: "#fff",
  //             fontFamily: "fantasy"
  //         }}>
  //             {list.length ? list[list.length - 1].num : null}
  //         </div>
  //     </div>
  // );

  /*
    
    flex: 1 1 0%;
    background: green;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 300px;
    font-weight: bold;
    color: #fff;
    font-family: fantasy;
    */
  return (
    <Layout>
      <style>{`body{background:url('${props.store.data.config.bgAuth.getUrl()}');background-size: cover;} .layoutcol{background:none !important;}`}</style>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
        <img
          style={{ height: 100 }}
          src={props.store.data.config.logo.getUrl()}
          alt=""
        />
      </div>
      <div
        style={{
          display: 'flex',
          gridGap: 20,
          gridAutoFlow: 'column',
          flex: 1,
        }}
      >
        <ListCmd
          store={props.store}
          state="En attente"
          label="En attente"
        ></ListCmd>
        <ListCmd
          store={props.store}
          state="En Préparation"
          label="En Préparation"
        ></ListCmd>
        <ListCmd store={props.store} state="Prêt" label="Prêt"></ListCmd>
        <ListCmd
          store={props.store}
          state="Livré"
          label="Prêt à emporter"
        ></ListCmd>
      </div>
    </Layout>
  );
};

const ListCmd = (props) => {
  return (
    <div
      style={{ margin: 10, flex: 1, background: 'rgba(204, 204, 204, 0.56)' }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: 25,
          background: 'rgba(204, 204, 204, 0.56)',
        }}
      >
        <h3 style={{ margin: 0 }}>{props.label}</h3>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.store.commandes
          .filter((item) => item.statepublic == props.state)
          .filter((item) => !item.table.id)
          .map((item, i) => {
            return (
              <div
                className="CommandeShowheader"
                style={{ margin: 10, width: 400, maxWidth: 400 }}
              >
                <div
                  className="commandeid"
                  style={{ padding: '5px 15px', fontSize: 40, minWidth: 100 }}
                >
                  {item.num}
                </div>
                <div className="tablename">
                  <div>{item.table && item.table.titre}</div>
                  <small>{item.name_user}</small>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default connectToStore(InfoScreen);
