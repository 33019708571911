import React, { useEffect, useState } from 'react'
import connectToStore from '../connect';
import Loading from '../com/Loading';
import CommandePrintViewCmd from './CommandePrintViewCmd';

/**
* @author
* @function CommandePrint
**/

const CommandePrint = (props) => {
    var data = props.createState(useState({}))
    useEffect(() => {
        data.loadFrom("getCommande", { id: props.match.params.id })
    }, [props.match.params.id])

    useEffect(() => {
        if (data.id) {
            setTimeout(() => {
                window.print();
                setTimeout(() => {
                    window.history.back();
                }, 500);
            }, 100);
        }
    }, [data.id]);
    if (data.$loading) return <Loading />;
    return <CommandePrintViewCmd logo={props.store.data.config.logo} item={data} />
}





export default connectToStore(CommandePrint)