import React, { useState, useEffect } from 'react';
import connectToStore from '../connect';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

/**
 * @author
 * @function Tables
 **/

const Tables = (props) => {
  var list = [...props.store.data.tables];
  list.unshift({
    id: null,
    titre: 'À emporter',
  });
  const hidetables = !!((props.store.user || {}).typeserveur || '').match(
    /sans/
  );
  return (
    <div>
      <div style={{ textAlign: 'center', paddingTop: 17 }}>
        <b>Veuillez sélectionner un table</b>
        <hr />
      </div>
      <Tabs defaultActiveKey="0">
        {(props.store.data.etages || []).map((elm) => (
          <TabPane tab={elm.titre} key={elm.id}>
            <div
              className="d-flex align-items-center"
              style={{ flexWrap: 'wrap', justifyContent: 'center' }}
            >
              {list
                .filter((table) => !hidetables || !table.id)
                .filter((table) => table.id_etage === elm.id || !table.id)
                .map((table, i) => (
                  <RestoTable
                    key={i}
                    info={table}
                    onSelect={(u) =>
                      props.onSelect ? props.onSelect(table, u) : null
                    }
                  />
                ))}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

const RestoTable = connectToStore((props) => {
  let cmd = props.store.commandes.find(
    (elm) =>
      props.info &&
      props.info.id &&
      elm.table &&
      elm.table.id * 1 === props.info.id * 1
  );
  let occuper = !!props.info.cmd;
  return (
    <div
      className={'tableitem ' + (occuper ? 'tableitem-occuper' : '')}
      onClick={(e) => {
        if (props.onSelect) {
          if (occuper) {
            if (
              cmd.id_user == props.store.user.id ||
              props.store.user.role != 'serveur'
            ) {
              props.onSelect('/app/commande/edit/' + cmd.id);
            }
          } else {
            props.onSelect(false);
          }
        }
      }}
    >
      <div> {props.info.titre}</div>
      {cmd ? <small>{cmd.name_user}</small> : null}
    </div>
  );
});

export default connectToStore(Tables);
