import React, { useEffect, useState } from 'react'
import connectToStore from '../connect';
import Loading from '../com/Loading';

/**
* @author
* @function CommandePrintAuto
**/

const CommandePrintAuto = (props) => {
    var data = props.createState(useState({}))
    useEffect(() => {
        data.loadFrom("getCommande", { id: props.match.params.id })
    }, [props.match.params.id])

    useEffect(() => {
        if (data.$loading || !data.table || !data.id) return;
        if (data.id) {
            const tpl = <div className="ticker" style={{ width: 267 }}>
                <style>{"#root{display:inline-block !important;min-height: 0 !important;}"}</style>
                <table className="table table-bordered" >
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                {elm.titre}
                            </th>
                        </tr>
                        <tr>
                            <th>Commande N° : </th>
                            <td>{data.id}</td>
                        </tr>
                        <tr>
                            <th>Table : </th>
                            <td>{data.table.titre}</td>
                        </tr>
                    </tbody>
                </table>
            </div>;



            // window.PrintElem();
            setTimeout(() => {
                // window.print();
                setTimeout(() => {
                    // window.history.back();
                }, 500);
            }, 100);
        }
    }, [data.id, data.$loading, data.table]);
    if (data.$loading || !data.table || !data.id) return <Loading />;

    const elm = (data.list || []).find(elm => elm.uid == props.match.params.uid);
    return (
        <div className="ticker" style={{ width: 267 }}>
            <style>{"#root{display:inline-block !important;min-height: 0 !important;}"}</style>
            <table className="table table-bordered" >
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            {elm.titre}
                        </th>
                    </tr>
                    <tr>
                        <th>Commande N° : </th>
                        <td>{data.id}</td>
                    </tr>
                    <tr>
                        <th>Table : </th>
                        <td>{data.table.titre}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}





export default connectToStore(CommandePrintAuto)




