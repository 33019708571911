import store from './store';
let accessToken = localStorage.getItem('accessToken') || '';
function api(apiName, data = {}, options = {}) {
  let url = api.getUrl(apiName, options);
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((rep) => rep.json())
    .then((rep) => {
      if ('header' in rep && 'body' in rep) {
        (rep.header || []).map((elm) => {
          switch (elm.name) {
            case 'log':
              console.log.apply(null, elm.data);
              break;
            case 'alert':
              alert.apply(null, elm.data);
              break;
            case 'eval':
              eval.apply(null, elm.data);
              break;
            case 'setAccessToken':
              accessToken = elm.data[0];
              localStorage.setItem('accessToken', accessToken);
              break;
            default:
              store.dispatch({ type: elm.name, payload: elm.data[0] });
              break;
          }
        });
        return rep.body;
      }
      return rep;
    });
}
api.getUrl = (apiName, options) => {
  let apiEndPoint =
    options.API_ENDPOINT || process.env.REACT_APP_API_ENDPOINT || '/api/';

  if (window.location.origin.match(/localhost|192\.168|b2c2/)) {
    apiEndPoint = 'https://erp.koolfood.tn/web/?api=';
  } else {
    apiEndPoint = 'https://pro.koolfood.tn/web/?api=';
  }

  let url = apiEndPoint + apiName;
  url += (url.match(/\?/i) ? '&' : '?') + 'access_token=' + accessToken;
  url += '&hn=' + window.location.hostname;
  if (window.location.origin.match(/localhost|192\.168|b2c2/)) {
    url += '&env=dev';
  } else {
    url += '&env=prod';
  }
  return url;
};

api.loading = (apiName, data = {}, options = {}) => {
  store.dispatch({ type: 'loading', payload: true });
  return api(apiName, data, options).then((rep) => {
    store.dispatch({ type: 'loading', payload: false });
    return rep;
  });
};
api.uploadFile = (file, data, progressCallback) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    (xhr.upload || xhr).addEventListener('progress', function (e) {
      var done = e.position || e.loaded;
      var total = e.totalSize || e.total;
      var progress = Math.round((done / total) * 100) - 1;
      if (progressCallback) progressCallback(progress);
    });
    xhr.addEventListener('load', function (e) {
      if (progressCallback) progressCallback(100);
      let rep = JSON.parse(e.currentTarget.responseText);
      if ('header' in rep && 'body' in rep) {
        (rep.header || []).map((elm) => {
          switch (elm.name) {
            case 'log':
              console.log.apply(null, elm.data);
              break;
            case 'alert':
              alert.apply(null, elm.data);
              break;
            case 'eval':
              eval.apply(null, elm.data);
              break;
            default:
              store.dispatch({ type: elm.name, payload: elm.data[0] });
              break;
          }
        });
      }
      resolve(rep.body);
    });
    xhr.open('post', api.getUrl('uploadfile', {}), true);
    var formData = new FormData();
    formData.append('file', file);
    formData.append('data', data);
    xhr.send(formData);
  });
};
window.api = api;
export default api;
