import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import connectToStore from '../connect';
import Loading from './Loading';
import TimesItem from './TimesItem';
import CommandePrintViewCmd from '../page/CommandePrintViewCmd';
import useInterval from '../store/useInterval';

/**
 * @author
 * @function CommandeShow
 **/

const CommandeShow = (props) => {
  let item = props.item;
  let state = props.state;
  let idType = props.idType;

  useEffect(() => {
    if (item.afficher['type_' + idType]) {
      if (item.alerttimetimer) clearInterval(item.alerttimetimer);
      let s = (new Date().getTime() - (item.alerttime || 0)) / 1000;
      if (s <= 5) {
        item.alerttimetimer = setInterval(() => {
          let s = (new Date().getTime() - (item.alerttime || 0)) / 1000;
          if (s > 5) {
            if (item.alerttimetimer) clearInterval(item.alerttimetimer);
            item.alertClass = false;
          } else {
            item.alertClass = !item.alertClass;
          }
          state.set({});
        }, 200);
      }
      state.set({});
    }
  }, [item.alerttime]);

  const compareValue = (a, b) => {
    if ((a || 0) < (b || 0)) return 1;
    if ((a || 0) > (b || 0)) return -1;
    return 0;
  };

  function dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return compareValue(b[property], a[property]);
      } else {
        return compareValue(a[property], b[property]);
      }
    };
  }
  if (!item.afficher['type_' + idType]) return null;
  let listAutre = item.list.filter((elm) => elm.id_typepreparateur !== idType);
  let list = item.list.filter((elm) => elm.id_typepreparateur === idType);
  // list = list.sort(dynamicSort('lastupdate'));
  let groups = list.reduce((g, v) => {
    if (!g.find((elm) => elm.id === v.id_groupeservable)) {
      g.push({
        titre: v.titre_groupeservable,
        id: v.id_groupeservable,
      });
    }
    return g;
  }, []);

  item.$api = (apiName, meta = {}) => {
    item.$loading = true;
    state.set({});
    return props.store.api(apiName, { meta, cmd: item }).then((res) => {
      item.$loading = false;
      if (res && res.cmd)
        for (let x in res.cmd) {
          item[x] = res.cmd[x];
        }
      state.set({});
      return item;
    });
  };

  item.print = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf('android') > -1;
    if (isAndroid && props.store.user && props.store.user.imprimer) {
      window.firebase
        .database()
        .ref('printer/' + props.store.user.imprimer)
        .push(JSON.parse(JSON.stringify(item)));
    } else {
      [].print(
        <CommandePrintViewCmd logo={props.store.data.config.logo} item={item} />
      );
    }
  };

  const update = (meta) => {
    return item.$api('saveCommande', meta);
  };

  return (
    <div
      className={
        'CommandeShow ' +
        (props.index === 0 ? ' CommandeShow-active' : '') +
        (item.alertClass ? ' CommandeShow-alert' : '')
      }
      id={'cmd-' + item.id}
      style={{ position: 'relative' }}
    >
      {item.$loading ? <Loading /> : null}
      <div className="CommandeShowheader">
        <div className="commandeid">{item.num}</div>
        <div className="tablename">
          <div>{item.table && item.table.titre}</div>
          <small>{item.name_user}</small>
        </div>
      </div>
      <div className="CommandeShowbody">
        {groups.map((groupe) => (
          <div key={groupe.id}>
            <div className="groupname">{groupe.titre}</div>
            {list
              .filter((elm) => elm.id_groupeservable === groupe.id)
              .map((elm, i) => (
                <CommandeShowItem
                  cmd={item}
                  to={props.to}
                  update={update}
                  key={i}
                  item={elm}
                  state={state}
                  idType={idType}
                />
              ))}
          </div>
        ))}
        {listAutre.length ? (
          <div>
            <div className="plat-autre">Plats d'autres services</div>
            <ul style={{ paddingLeft: 7 }}>
              {listAutre.map((elm, i) => (
                <li className="plat-autre-item" key={'autre-' + i}>
                  <span>•</span>
                  <span>
                    {elm.qte} <small>x</small>{' '}
                  </span>
                  <span>{elm.titre}</span>
                  <span> - {elm.state}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      <hr />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ flex: 1 }}></span>
        <button className={'btn btn-sm btn-dark'} onClick={(e) => item.print()}>
          Imprimer
        </button>
      </div>
    </div>
  );
};

const CommandeShowItem = (props) => {
  let { item, state, update, cmd } = props;
  let ss = useState({ alertatt: false }).toState();
  useInterval(() => {
    ss.alertatt = !ss.alertatt;
    ss.set();
  }, 300);
  item.state = item.state || 'En attente';
  item.setState = (val) => {
    item.state = val;
    state.set({});
    update({ item: item.id, prop: 'state' });
  };
  item.print = () => {
    [].printAC(item.titre, cmd.num, cmd.table.titre);
    // props.to(`/app/commande/printauto/${cmd.id}/${item.uid}`)
  };

  // #ccff00
  return (
    <div
      className={
        'CommandeShowItem ' +
        (ss.alertatt && item.state == 'En attente'
          ? 'CommandeShowItem-alertatt'
          : 'CommandeShowItem-not-alertatt')
      }
      key={item.id}
    >
      <span>{item.qte}</span>
      <div>
        <div>{item.titre}</div>
        {(item.supplements || []).filter((sup) => sup.checked).length ||
        (item.supplementspayants || []).filter((sup) => sup.checked).length ||
        (item.choix || []).filter((sup) => sup.checked).length ||
        item.emplacement ||
        (item.ingredientsclient || []).filter((sup) => sup.unchecked).length ? (
          <div className="divcomment">
            <ul style={{ paddingLeft: 22 }}>
              {(item.supplements || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'s' + sup.id}>{sup.titre_supplements}</li>;
                })}
              {(item.choix || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'s' + sup.id}>{sup.titre}</li>;
                })}
              {(item.supplementspayants || [])
                .filter((sup) => sup.checked)
                .map((sup) => {
                  return <li key={'sp' + sup.id}>{sup.titre_supplements}</li>;
                })}
              {(item.ingredientsclient || [])
                .filter((sup) => sup.unchecked)
                .map((sup) => {
                  return (
                    <li key={'i' + sup.id}>
                      <del>{sup.titre_ingredients}</del>
                    </li>
                  );
                })}
              {item.emplacement ? (
                <li key={'i' + item.emplacement}>{item.emplacement}</li>
              ) : null}
            </ul>
          </div>
        ) : null}

        {item.comment ? <div className="divcomment">{item.comment}</div> : null}

        {!item.terminer && !item.actionserveur ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zoom: 0.6,
              margin: '6px 0 -11px 0',
            }}
          >
            <button
              onClick={(e) => item.setState('En attente')}
              className={
                'btn btn-sm btn-' +
                (item.state === 'En attente' ? 'success' : 'dark')
              }
            >
              En attente
            </button>
            <button
              onClick={(e) => item.setState('En Préparation')}
              className={
                'btn btn-sm btn-' +
                (item.state === 'En Préparation' ? 'success' : 'dark')
              }
            >
              En Préparation
            </button>
            <button
              onClick={(e) => item.setState('Prêt')}
              className={
                'btn btn-sm btn-' + (item.state === 'Prêt' ? 'success' : 'dark')
              }
            >
              Prêt
            </button>
            <button
              onClick={(e) => item.print()}
              className={'btn btn-sm btn-dark'}
            >
              A.C.
            </button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              zoom: 0.8,
              margin: '6px 0 -11px 0',
            }}
          >
            <span className="badge badge-warning">{item.state}</span>
            <button
              onClick={(e) => item.print()}
              className={'btn btn-sm btn-dark'}
            >
              A.C.
            </button>
          </div>
        )}
        <TimesItem item={item} />
      </div>
    </div>
  );
};

export default connectToStore(CommandeShow);
