import React, { useState, useEffect } from 'react'
import connectToStore from '../connect';


/**
* @author
* @function Tablettes
**/

const Tablettes = (props) => {

    return (
        <div>

            <div style={{ textAlign: 'center', paddingTop: 17 }}>
                <b>Veuillez sélectionner une tablette</b>
                <hr />
            </div>

            <div className="d-flex align-items-center" style={{ flexWrap: "wrap", justifyContent: "center" }}>
                <RestoTablette
                    info={{ id: 0, titre: "Aucun" }}
                    onSelect={(u) => props.onSelect ? props.onSelect(null, null) : null}
                />
                {
                    props
                        .store
                        .data
                        .tablettes
                        .map((table, i) => (
                            <RestoTablette
                                key={i}
                                info={table}
                                onSelect={(u) => props.onSelect ? props.onSelect(table, u) : null}
                            />
                        ))
                }
            </div>

        </div>
    )
}

const RestoTablette = connectToStore(props => {
    // let cmd = props.store.commandes.find(elm => elm.table.id * 1 === props.info.id * 1);
    // let occuper = !!cmd;
    let occuper = false;
    return (
        <div className={'tableitem ' + (occuper ? "tableitem-occuper" : "")} onClick={e => props.onSelect ? props.onSelect(false) : null}>
            {props.info.titre}
        </div>
    )
})



export default connectToStore(Tablettes)