import React from 'react'

class TimesItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isOn: false, time: Date.now(), diff: Date.now() - (props.item.currenttime || 0) }
        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
    }
    startTimer() {
        this.setState({ isOn: true, time: Date.now() });
        this.timer = setInterval(() => this.setState({ time: Date.now() }), 1000);
    }
    stopTimer() {

        this.setState({ isOn: false, time: Date.now() - this.state.diff })
        clearInterval(this.timer)
    }
    componentDidMount() {
        this.startTimer();
    }
    componentWillUnmount() {
        this.stopTimer();
    }
    msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
        if (mins < 10) mins = "0" + mins;
        if (secs < 10) secs = "0" + secs;
        if (hrs < 10) hrs = "0" + hrs;
        return hrs + ':' + mins + ':' + secs;
    }
    render() {

        const { item } = this.props;
        var list = [];
        item.times = item.times || {};
        switch (item.state) {
            case "En attente":
                list.push({
                    label: "En attente",
                    time: this.state.time - item.times.start,
                });
                break;
            case "En Préparation":
                item.times.preparation = item.times.preparation || item.times.start;
                list.push({
                    label: "En attente",
                    time: item.times.preparation - item.times.start,
                });
                list.push({
                    label: "En Préparation",
                    time: (item.times.pret || this.state.time) - item.times.preparation,
                });
                break;
            case "Prêt":
                item.times.preparation = item.times.preparation || item.times.start;
                item.times.pret = item.times.pret || item.times.preparation;
                list.push({
                    label: "En attente",
                    time: item.times.preparation - item.times.start,
                });
                list.push({
                    label: "En Préparation",
                    time: item.times.pret - item.times.preparation,
                });
                list.push({
                    label: "En Livraison",
                    time: (item.times.livre || this.state.time) - item.times.pret,
                });
                break;
            case "Livré":
            case "Payée":
                item.times.preparation = item.times.preparation || item.times.start;
                item.times.pret = item.times.pret || item.times.preparation;
                item.times.livre = item.times.livre || item.times.pret;
                list.push({
                    label: "En attente",
                    time: item.times.preparation - item.times.start,
                });
                list.push({
                    label: "En Préparation",
                    time: item.times.pret - item.times.preparation,
                });
                list.push({
                    label: "En Livraison",
                    time: item.times.livre - item.times.pret,
                });
                // list.push({
                //     label: "Livré",
                //     time: this.state.time - item.times.livre,
                // });
                break;
            // case "Payée":
            //     item.times.preparation = item.times.preparation || item.times.start;
            //     item.times.pret = item.times.pret || item.times.preparation;
            //     item.times.livre = item.times.livre || item.times.pret;
            //     item.times.payee = item.times.payee || item.times.livre;
            //     list.push({
            //         label: "En attente",
            //         time: item.times.preparation - item.times.start,
            //     });
            //     list.push({
            //         label: "En Préparation",
            //         time: item.times.pret - item.times.preparation,
            //     });
            //     list.push({
            //         label: "Prêt",
            //         time: item.times.livre - item.times.pret,
            //     });
            //     list.push({
            //         label: "Livré",
            //         time: item.times.payee - item.times.livre,
            //     });
            //     break;
        }
        list.map(elm => {
            elm.timestr = this.msToTime(elm.time);
        });
        return (
            <div style={{ display: 'flex', margin: '20px 0px -12px 0', zoom: 0.7 }}>
                {list.map((elm, i) => {
                    return (
                        <div key={i} style={{ margin: 2, flex: 1, padding: '3px 7px', display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#c9dc4f' }}>
                            <div>{elm.label}</div>
                            <b>{elm.timestr}</b>
                        </div>
                    )
                })}
            </div>
        )
    }

}


export default TimesItem;
