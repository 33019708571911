import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Auth from './page/Auth';
import { Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import Loading from './com/Loading';
import Commande from './page/Commande';
import connectToStore from './connect';
import DefaultLayout from './layout/DefaultLayout';
import PageNotFound from './page/PageNotFound';
import CommandePrint from './page/CommandePrint';
import Encaissement from './page/Encaissement';
import Atom from './Atom/Atom';
import CommandePrintAuto from './page/CommandePrintAuto';
import InfoScreen from './page/InfoScreen';
import Printer from './page/Printer';
import DemandeAchat from './page/DemandeAchat';
import PublicCmd from './page/PublicCmd';
import FBAuth from './com/FBAuth';
import PublicMesCmds from './page/PublicMesCmds';
import PrintDialog from './page/PrintDialog';
import GestionDesPlats from './page/GestionDesPlats';
import PublicMesAdresses from './page/PublicMesAdresses';

const useLayout = (Layout, initalLayoutState = {}) => (
  Com,
  defaultProps = {}
) => (props) => {
  let layoutActions = {};
  return (
    <Layout defaultState={initalLayoutState} actions={layoutActions}>
      <Com {...defaultProps} {...props} layout={layoutActions} />
    </Layout>
  );
};

const defaultServeurProps = {
  actions: ['serveur-newcmd', 'serveur-encours', 'serveur-historique'],
};
const defaultCaissierProps = {
  actions: [
    'caissier-newcmd',
    'caissier-encours',
    'caissier-historique',
    'encaissement',
  ],
};
const defaultRespProps = {
  actions: ['resp-newcmd', 'resp-encours', 'resp-historique'],
};
const defaultAdminProps = {
  actions: ['caissier-encours', 'caissier-historique', 'encaissement'],
};
const defaultPosteProps = {
  actions: [
    'poste-encours',
    'poste-historique',
    'demandeachat',
    // 'gestiondesplats',
  ],
};

const CommandeListServeurScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultServeurProps,
  view: 'list-serveur',
});
const CommandeListPostScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultPosteProps,
  view: 'list-poste',
});
const CommandeHistoriquePosteScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultPosteProps,
  view: 'historique-poste',
});
const CommandeHistoriqueServeurScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultServeurProps,
  view: 'historique',
});

const CommandeNewScreen = useLayout(DefaultLayout)(Commande, { view: 'new' });
const CommandeEditScreen = useLayout(DefaultLayout)(Commande, { view: 'edit' });

const CommandeServeurDefaultScreen = useLayout(DefaultLayout)(
  Commande,
  defaultServeurProps
);
const CommandePosteDefaultScreen = useLayout(DefaultLayout)(
  Commande,
  defaultPosteProps
);
const AuthScreen = useLayout(DefaultLayout, { offline: true })(Auth);
const PageNotFoundScreen = useLayout(DefaultLayout)(PageNotFound);

const CommandeCaissierDefaultScreen = useLayout(DefaultLayout)(
  Commande,
  defaultCaissierProps
);
const CommandeListCaissierScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultCaissierProps,
  view: 'list-caissier',
});
const CommandeHistoriqueCaissierScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultCaissierProps,
  view: 'historique',
});

const CommandeRespDefaultScreen = useLayout(DefaultLayout)(
  Commande,
  defaultRespProps
);
const CommandeListRespScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultRespProps,
  view: 'list-resp',
});

const CommandeAdminDefaultScreen = useLayout(DefaultLayout)(
  Commande,
  defaultAdminProps
);
const CommandeListAdminScreen = useLayout(DefaultLayout)(Commande, {
  ...defaultAdminProps,
  view: 'list-resp',
});

const EncaissementCaissierScreen = useLayout(DefaultLayout)(
  Encaissement,
  defaultCaissierProps
);
const EncaissementAdminScreen = useLayout(DefaultLayout)(
  Encaissement,
  defaultAdminProps
);

const CommandeTabletteEditScreen = useLayout(DefaultLayout)(Commande, {
  view: 'tablette',
  tablette: true,
});
const InfoScreenView = useLayout(DefaultLayout, { hideMenu: true })(InfoScreen);
const PublicCmdView = useLayout(DefaultLayout, {})(PublicCmd);
const PublicMesCmdsView = useLayout(DefaultLayout, {})(PublicMesCmds);
const PublicMesAdressesView = useLayout(DefaultLayout, {})(PublicMesAdresses);

const DemandeAchatScreen = useLayout(DefaultLayout)(
  DemandeAchat,
  defaultPosteProps
);

const GestionDesPlatsScreen = useLayout(DefaultLayout)(
  GestionDesPlats,
  defaultPosteProps
);

function App({ store, dispatch }) {
  const forceB2C = false;
  const forceProd = false;
  const bloquerSync = window.location.host.match(/b2c/i) || forceB2C;
  const isProd =
    window.location.host.match(/pro/i) ||
    window.location.host.match(/b2c\./i) ||
    forceProd;

  useEffect(() => {
    dispatch('init');
    if (bloquerSync) return;
    return store.syncEach(
      3000,
      (ss) => {
        let body = {};
        body.commandes = ss.commandes.map((cmd) => ({
          id: cmd.id,
          key: cmd.key,
        }));
        return body;
      },
      store
    );
  }, []);

  useEffect(() => {
    var t = null;
    if (store.nbralertserveur > 0 && store.user && store.user.id) {
      t = setInterval(() => {
        store.audioNotification();
      }, 3000);
    }
    return () => t && clearInterval(t);
  }, [store.nbralertserveur > 0, store.user]);

  if (store.initLoading) return <Loading />;

  if (bloquerSync && !store.publicUserUpdated) {
    return (
      <>
        <FBAuth />
        <Loading />
      </>
    );
  }

  if (store.error)
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
          background: 'rgba(255,255,255,0.5)',
        }}
      >
        {store.error}
      </div>
    );

  if (bloquerSync)
    return (
      <>
        <FBAuth />
        <Switch>
          {store.publicUser ? (
            <Route exact path="/" component={PublicMesCmdsView} />
          ) : (
            <Route exact path="/" component={PublicCmdView} />
          )}
          {store.publicUser ? (
            <Route exact path="/nouvelle-commande" component={PublicCmdView} />
          ) : null}
          {store.publicUser ? (
            <Route
              exact
              path="/mes-adresses"
              component={PublicMesAdressesView}
            />
          ) : null}
          <Redirect to="/" />
        </Switch>
      </>
    );

  const { user } = store;

  let authUrl = '/app/auth?next=' + (window.location || {}).pathname;
  let authNext = new URLSearchParams(window.location.search).get('next');
  return (
    <>
      {isProd && user && user.role !== 'serveur' ? (
        <Printer name={user.type} />
      ) : null}
      <PrintDialog />
      <Switch>
        {user && user.role === 'serveur' ? (
          <Route exact={false} path="/app/serveur/*">
            <Switch>
              <Route
                exact={true}
                path="/app/serveur/default"
                component={CommandeServeurDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/serveur/commande/edit/:id"
                component={CommandeEditScreen}
              />
              <Route
                exact={true}
                path="/app/serveur/commande/new"
                component={CommandeNewScreen}
              />
              <Route
                exact={true}
                path="/app/serveur/commande/list"
                component={CommandeListServeurScreen}
              />
              <Route
                exact={true}
                path="/app/serveur/commande/historique"
                component={CommandeHistoriqueServeurScreen}
              />
              <Redirect to={authNext ? authNext : '/app/serveur/default'} />
            </Switch>
          </Route>
        ) : null}

        {user && user.role === 'caissier' ? (
          <Route exact={false} path="/app/caissier/*">
            <Switch>
              <Route
                exact={true}
                path="/app/caissier/default"
                component={CommandeCaissierDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/caissier/commande/edit/:id"
                component={CommandeEditScreen}
              />
              <Route
                exact={true}
                path="/app/caissier/commande/new"
                component={CommandeNewScreen}
              />
              <Route
                exact={true}
                path="/app/caissier/commande/list"
                component={CommandeListCaissierScreen}
              />
              <Route
                exact={true}
                path="/app/caissier/commande/historique"
                component={CommandeHistoriqueCaissierScreen}
              />
              <Route
                exact={true}
                path="/app/caissier/encaissement"
                component={EncaissementCaissierScreen}
              />
              <Route
                exact={true}
                path="/app/poste/gestion/plats"
                component={GestionDesPlatsScreen}
              />
              <Redirect to={authNext ? authNext : '/app/caissier/default'} />
            </Switch>
          </Route>
        ) : null}

        {user && user.role === 'poste' ? (
          <Route exact={false} path="/app/poste/*">
            <Switch>
              <Route
                exact={true}
                path="/app/poste/:poste"
                component={CommandePosteDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/poste/commande/list"
                component={CommandeListPostScreen}
              />
              <Route
                exact={true}
                path="/app/poste/commande/historique"
                component={CommandeHistoriquePosteScreen}
              />
              <Route
                exact={true}
                path="/app/poste/demande/achat"
                component={DemandeAchatScreen}
              />
              <Route
                exact={true}
                path="/app/poste/gestion/plats"
                component={GestionDesPlatsScreen}
              />
              <Redirect
                to={
                  authNext ? authNext : '/app/poste/' + user.type.toLowerCase()
                }
              />
            </Switch>
          </Route>
        ) : null}

        {user && user.role === 'responsable-front' ? (
          <Route exact={false} path="/app/responsable-front/*">
            <Switch>
              <Route
                exact={true}
                path="/app/responsable-front/default"
                component={CommandeRespDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-front/commande/edit/:id"
                component={CommandeEditScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-front/commande/new"
                component={CommandeNewScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-front/commande/list"
                component={CommandeListRespScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-front/commande/historique"
                component={CommandeHistoriqueCaissierScreen}
              />
              <Redirect
                to={authNext ? authNext : '/app/responsable-front/default'}
              />
            </Switch>
          </Route>
        ) : null}

        {user && user.role === 'responsable-back' ? (
          <Route exact={false} path="/app/responsable-back/*">
            <Switch>
              <Route
                exact={true}
                path="/app/responsable-back/default"
                component={CommandeRespDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-back/commande/edit/:id"
                component={CommandeEditScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-back/commande/new"
                component={CommandeNewScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-back/commande/list"
                component={CommandeListRespScreen}
              />
              <Route
                exact={true}
                path="/app/responsable-back/commande/historique"
                component={CommandeHistoriqueCaissierScreen}
              />
              <Redirect
                to={authNext ? authNext : '/app/responsable-back/default'}
              />
            </Switch>
          </Route>
        ) : null}

        {user && user.role === 'admin' ? (
          <Route exact={false} path="/app/admin/*">
            <Switch>
              <Route
                exact={true}
                path="/app/admin/default"
                component={CommandeAdminDefaultScreen}
              />
              <Route
                exact={true}
                path="/app/admin/commande/edit/:id"
                component={CommandeEditScreen}
              />
              <Route
                exact={true}
                path="/app/admin/commande/new"
                component={CommandeNewScreen}
              />
              <Route
                exact={true}
                path="/app/admin/commande/list"
                component={CommandeListAdminScreen}
              />
              <Route
                exact={true}
                path="/app/admin/commande/historique"
                component={CommandeHistoriqueCaissierScreen}
              />
              <Route
                exact={true}
                path="/app/admin/encaissement"
                component={EncaissementAdminScreen}
              />
              <Redirect to={authNext ? authNext : '/app/admin/default'} />
            </Switch>
          </Route>
        ) : null}

        {user
          ? [
              <Route
                key={1}
                exact={true}
                path="/app/commande/edit/:id"
                component={CommandeEditScreen}
              />,
              <Route
                key={2}
                exact={true}
                path="/app/commande/print/:id"
                component={CommandePrint}
              />,
              <Route
                key={3}
                exact={true}
                path="/app/commande/printauto/:id/:uid"
                component={CommandePrintAuto}
              />,
            ]
          : [
              <Route
                key={1}
                exact={true}
                path="/app/auth"
                component={AuthScreen}
              />,
            ]}

        {user && user.role === 'tablette' ? (
          <Route exact={false} path="/app/tablette/*">
            <Switch>
              <Route
                exact={true}
                path="/app/tablette/default"
                component={CommandeTabletteEditScreen}
              />
              <Redirect to={'/app/tablette/default'} />
            </Switch>
          </Route>
        ) : null}

        <Route
          key={1}
          exact={true}
          path="/app/infos-screen"
          component={InfoScreenView}
        />
        <Route key={2} exact={true} path="/app/printer" component={Printer} />

        {user ? (
          <Redirect
            to={authNext ? authNext : '/app/' + user.role + '/default'}
            from="/app/auth"
          />
        ) : (
          <Redirect to={authUrl} />
        )}
        {user ? <Redirect to={'/app/' + user.role + '/default'} /> : null}
      </Switch>
    </>
  );
}

export default connectToStore(App);
