import React, { useState } from 'react'
import connectToStore from '../connect';
import State from './State';

/**
* @author
* @function Layout
**/

const Layout = props => {
    let state = useState({ titre: props.titre || "", view: props.view || "default" }).toState();
    let viewsObj = props.views || {};
    let views = Object.entries(viewsObj).map(([key, view]) => ({ ...view, key }));
    let View = viewsObj[state.view];


    const backClick = () => {
        props.to("/app/")
    }

    let screeninfo = props.match && props.match.path == "/app/infos-screen";
    return (
        <>
            {(!screeninfo && props.store.user && props.store.user.role != "tablette") ? <State /> : null}
            <div className="layoutcol">

                {(props.store.user && props.store.user.role != "tablette" && (View || props.titre)) ? (
                    <div style={{ background: '#eee', padding: '10px' }}>
                        <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                            <button className="btn btn-primary" onClick={e => backClick()} style={{ marginRight: 15 }}> <i className="fa fa-arrow-left" /> </button>
                            <h3 style={{ margin: 0, fontSize: 25 }}>
                                {(View && View.titre) || props.titre}
                            </h3>
                        </div>
                    </div>
                ) : null}

                <div className="layoutcol" style={{ position: 'relative' }}>
                    <div className="container layoutcol" style={{ minHeight: 400, position: 'relative', background: 'rgba(255,255,255,0.5)', margin: '5px auto', ...(props.style || {}) }}>
                        {View ? <View.Com /> : props.children}
                    </div>
                </div>

            </div>
        </>
    )
}


export default connectToStore(Layout)