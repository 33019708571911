import React, { useState, useEffect } from "react";
import connectToStore from "../connect";
import { Redirect } from "react-router-dom";


function Auth(props) {
  var data = props.store.createState(useState({}));
  useEffect(data.cleanup, []);

  if (props.store.user) return <Redirect to='/app/' />;
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '80vh' }} >

      {data.form("applogin", (
        <div className="row" style={{ maxWidth: 300 }}>
          <div className="col-12"> <h1 className="h3 mb-3 font-weight-normal">Authentication</h1></div>
          <div className="col-12">
            {data.inputText("login", "Nom d'utilisateur", { placeholder: "Nom d'utilisateur", required: true })}
          </div>
          <div className="col-12">
            {data.inputText("password", "Mot de passe", { type: "password", placeholder: "Mot de passe", required: true })}
          </div>
          <div className="col-12">
            <button className="btn btn-primary" type="submit" >Connecter</button>
          </div>
        </div>
      ))}

    </div>
  );
}
export default connectToStore(Auth);
