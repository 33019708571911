import React from "react";
import { createStore } from "redux";
import config from "../config";
import { Redirect } from "react-router-dom";
import Loading from "../com/Loading";
import { Modal, Button, Form } from 'react-bootstrap';
import Dialog from "../com/Dialog";
import Paginations from "../com/Pagination";
import { Switch, Route } from 'react-router'


class RouteList {
    constructor() {
        this.list = [];
        this.initRouteCallback = elm => elm
        this._defaultProps = {};
        this._defaultPropsCom = {};
    }
    defaultProps(data) {
        this._defaultProps = { ...this._defaultProps, ...data };
        return this;
    }
    defaultPropsCom(data) {
        this._defaultPropsCom = { ...this._defaultPropsCom, ...data };
        return this;
    }
    initRoute(callback) {
        this.initRouteCallback = callback
        return this;
    }
    add(obj, Com = null) {
        if (typeof obj === "string") {
            obj = { path: obj }
        }
        if (Com && Com.$$typeof && typeof Com.$$typeof === "symbol") {
            let ComType = Com.type;
            let ComProps = Com.props;
            Com = args => <ComType {...ComProps} {...args} />
        }

        let newObj = this._defaultProps || {};
        if (obj.$$typeof && typeof obj.$$typeof === "symbol") {
            switch (obj.type.name) {
                case "Redirect":
                    newObj = { ...newObj, ...obj.props, type: "Redirect" }
                    break;
                default:
                    newObj = { ...newObj, ...obj.props, type: obj.type.name }
                    break;
            }


        } else {
            newObj = { ...newObj, ...obj }
        }
        let ComF = newObj.component || Com;
        let propsCom = this._defaultPropsCom;
        this.list.push({ ...newObj })
        return this;
    }

    getRoutes(filter = {}) {
        this.list.sort((a, b) => a.type === "Redirect" ? 1 : -1)
        return this.list
            .filter(elm => {
                for (let x in filter) {
                    if (Array.isArray(elm[x]) && !Array.isArray(filter[x])) {
                        if (!elm[x].includes(filter[x])) return false;
                    } else {
                        if (elm[x] !== filter[x]) return false;
                    }
                }
                return true;
            })
            .map(this.initRouteCallback).map(function (route, key) {
                switch (route.type) {
                    case "Redirect":
                        let url = route.to;
                        if (route.useNext) {
                            url += url.match(/\?/) ? "&" : "?"
                            url += "next=" + window.location.pathname
                        }
                        if (route.orNext) {
                            var next = (new URLSearchParams(window.location.search)).get("next");
                            if (next) {
                                url = next;
                            }
                        }
                        return <Redirect key={key} to={url} />
                    default:
                        let { exact = true, path = "/", component } = route;
                        let passObj = { exact, path, component };
                        // if (route.layout) {
                        //     const ComponentA = props => {
                        //         let layoutActions = {}
                        //         let Layout = route.layout
                        //         const Com = route.component
                        //         if (Layout && Layout.$$typeof && typeof Layout.$$typeof === "symbol") {
                        //             let LayoutType = Layout.type;
                        //             let LayoutProps = Layout.props;
                        //             Layout = args => <LayoutType {...LayoutProps} {...args} />
                        //         }
                        //         return <Layout actions={layoutActions}> <Com {...props} layout={layoutActions} /> </Layout>
                        //     }
                        //     passObj = { ...passObj, component: ComponentA }
                        // } else {
                        //     passObj = { ...passObj, component: route.component }
                        // }
                        return <Route key={key} {...passObj} />
                }
            })
    }

    getSuitch(filter = {}) {
        return (
            <Switch>
                {this.getRoutes(filter)}
            </Switch>
        );
    }

    getSingleViewRoute(path, Com, layout = null) {
        return (new RouteList)
            .defaultProps({ layout })
            .add(<Route exact path={path} />, Com)
            .add(<Redirect to={path} useNext={true} />)
            .getSuitch()
    }
}


export default RouteList;