import _ from 'lodash';
import api from './api';
import React from 'react';
import ReactDOM from 'react-dom';
import * as JSPM from 'jsprintmanager';
// import html2canvas from 'html2canvas';
// import domtoimage from 'dom-to-image';

Array.prototype.createBind = (refVar, callback) => {
    return (name, options = false) => {
        let sendBind = (options === true || (typeof options == "object" && options.sendBind));
        let onChange = typeof options == "object" && options.onChange;
        let value = (!name || name == "") ? (refVar || (sendBind ? {} : "")) : _.get(refVar, name, sendBind ? {} : "");
        const setValue = newValue => {
            if (!name || name == "") {
                if (typeof newValue === "object" && typeof refVar === "object") {
                    for (let x in newValue) {
                        _.set(refVar, x, newValue[x]);
                    }
                }
            } else {
                _.set(refVar, name, newValue);
            }
            if (onChange) onChange(newValue);
            if (callback) callback(newValue);
        }
        let rep = {
            value,
            onChange: e => {
                let val = null;
                if (e && typeof e === "object" && 'target' in e) {
                    switch (e.target.type) {
                        case "checkbox":
                            val = !!e.target.checked;
                            break;
                        default:
                            val = e.target.value;
                            break;
                    }
                } else {
                    val = e;
                }
                setValue(val);
            }
        }
        if (sendBind) {
            rep.bind = [].createBind(value, e => setValue(value))
        }
        return rep;
    }
}

Array.prototype.removeProps = function (props, obj) {
    let obj1 = {}
    let propsList = props.split(",");
    for (let x in obj) {
        if (!propsList.includes(x)) {
            obj1[x] = obj[x];
        }
    }
    return obj1;
}

Array.prototype.toBindState = function (props, options = {}) {
    let [state, setState] = this;
    state = { ...state, ...(props.value || {}) };
    state.bind = [].createBind(state, e => {
        setState({ ...state });
        if (props.onChange) props.onChange(state);
    });
    return state;
}

Array.prototype.toState = function (options = {}) {
    let [state, setState] = this;
    state.setDataInObj = (data, refObj) => {
        if (data && typeof data === "object") {
            for (let x in data) {
                _.set(refObj, x, data[x]);
            }
        }
    }
    state.set = (obj = null, val = null) => {
        if (obj && typeof obj === "string") {
            _.set(state, obj, val);
        } else if (obj) {
            for (let x in obj) {
                _.set(state, x, obj[x]);
            }
        }
        state = { ...state };
        setState(state);
    }
    state.getState = () => state;
    state.get = (path, defaultValue = null, refObj = null) => {
        let obj = refObj || state;
        let value = _.get(obj, path, defaultValue);
        return value;
    }
    state.bind = [].createBind(state, e => state.set());
    state.setStatusFromAPI = (rep, refObj = null) => {
        let ref = rep.ctrl || rep || {};
        if (refObj) {
            if (typeof refObj === "string") {
                _.set(state, refObj, {});
            } else if (typeof refObj === "object") {
                for (let x in refObj) {
                    delete refObj[x];
                }
            }
        }
        for (let x in ref) {
            if (ref[x].error) {
                state.setStatus(x, "error", typeof ref[x].error === "string" ? ref[x].error : "", refObj);
            } else if (ref[x].success) {
                state.setStatus(x, "success", typeof ref[x].success === "string" ? ref[x].success : "", refObj);
            }
        }
    }
    state.setStatus = (name, status = "", message = "", refObj = null) => {
        if (typeof refObj === "string") {
            name = refObj + "." + name;
            refObj = null;
        }
        let obj = refObj || state;
        _.set(obj, name, {
            hasFeedback: true,
            validateStatus: status,
            help: message
        });
        state.set();
    }
    state.bindObj = (name, refObj = null) => {
        let obj = refObj || state;
        let value = _.get(obj, name, null);
        return value || {};
    }
    state.onEnter = (callback, keys = []) => {
        return {
            onKeyPress: e => {
                if ((!keys.length && e.key === 'Enter') || keys.includes(e.key)) {
                    if (callback) callback();
                }
            }
        }
    }
    return state;
}

Array.prototype.toAPI = function (options = {}) {
    let state = this.toState(options);
    state.$loading = state.$loading || false;
    state.action = (action, data) => {
        return api(options.api, { action, state, data, options });
    }
    state.save = (data = {}) => {
        state.set({ $loading: true });
        return state.action("save", data)
            .then(rep => {
                state.set({ $loading: false });
                if (rep && typeof rep === "object") {
                    for (let x in rep) {
                        state.set(x, rep[x]);
                    }
                }
                return rep;
            });
    }
    state.load = (filter = {}) => {
        state.set({ $loading: true });
        return state.action("load", filter)
            .then(rep => {
                state.set({ $loading: false });
                if (rep && typeof rep === "object") {
                    state.set({ ...rep });
                }
                return rep;
            });
    }
    return state;
}

Array.prototype.toCRUD = function (options = {}) {
    let state = this.toState(options);
    state.$loading = state.$loading || false;
    state.rows = state.rows || [];
    state.count = state.count || 0;
    state.nbrPages = state.nbrPages || 0;
    state.filter = state.filter || {};
    state.page = Math.max(state.page || 1, 1);
    state.length = Math.min(state.length || 10, 50);
    state.item = state.item || null;

    state.action = (action, data = {}) => {
        return api(options.api, { action, data, options });
    }

    const initItem = item => {
        item.$loading = item.$loading || false;
        item.bind = name => state.bind(name, item);
        item.bindObj = name => state.bindObj(name, item);
        item.$set = (obj = {}) => {
            state.setDataInObj(obj, item);
            state.set();
            return item;
        }
        item.$save = (obj = {}) => {
            item.$loading = true;
            item.$set(obj);
            return new Promise((resolve, reject) => {
                state.action("save", item).then(rep => {
                    item.$loading = false;
                    if (rep && rep.item) {
                        item.$set(rep.item);
                    }
                    if (rep && (rep.ok || rep.success)) {
                        state.load();
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        }
        item.$delete = (confirmMessage = null) => {
            return new Promise((resolve, reject) => {
                if (confirmMessage) if (!window.confirm(confirmMessage)) return resolve(false);
                state.action("delete", item).then(rep => {
                    if (rep && (rep.ok || rep.success)) {
                        state.load();
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        }
        item.$select = () => {
            state.set({ item });
            return item;
        }
        if (options.initItem) options.initItem(item);
        return item;
    }

    state.load = (filter = {}, $page = null, $length = null) => {
        state.filter = { ...state.filter, ...filter };
        state.page = Math.max($page || state.page || 1, 1);
        state.length = Math.min($length || state.length || 10, 50);
        state.$loading = true;
        state.set();
        return state.action("load", { filter: state.filter, page: state.page, length: state.length })
            .then(result => {
                state.set({
                    ...result,
                    rows: (result.rows || []).map(initItem),
                    $loading: false
                });
            });
    }

    state.goToPage = page => state.load({}, page);

    state.newItem = (item = {}) => {
        item = item || {};
        initItem(item);
        return item;
    }

    state.antTableProps = (obj = {}) => {
        return {
            loading: state.$loading,
            pagination: { total: state.count },
            dataSource: state.rows,
            onChange: (pagination, filters, sorter) => state.goToPage(pagination.current),
            ...obj
        }
    }
    return state;
}



Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        var k = new_index - this.length;
        while ((k--) + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this;
}
    ;
Array.prototype.move_up = function (index) {
    if (index > 0) {
        return this.move(index, index - 1);
    }
    return this;
}
    ;
Array.prototype.move_down = function (index) {
    if (index < this.length - 1) {
        return this.move(index, index + 1);
    }
    return this;
}

function flatten(list, result) {
    list.map(elm => {
        result.push({ ...elm, children: null });
        if (elm.children && elm.children.length) flatten(elm.children, result);
    });
};


Array.prototype.flatten = function () {
    let result = [];
    flatten(this, result);
    return result;
};

var ListEvents = [];
Array.prototype.on = function (callback) {
    ListEvents.push({ events: this, callback })
    return this;
}
Array.prototype.emit = function (data) {
    ListEvents.map(arg => {
        if (_.intersection(arg.events, this).length) {
            if (arg.callback) {
                arg.callback(data)
            }
        }
    });
    return this;
}

Array.prototype.print = (tpl, callback) => {
    const container = window.document.querySelector("#printelm");
    ReactDOM.unmountComponentAtNode(container)
    container.innerHTML = "";
    ReactDOM.render(tpl, container, () => {
        let elem = window.document.querySelector("#printelm > *");
        // return [].print1(elem);
        var mywindow = window.open('', 'PRINT', 'height=300,width=300');
        mywindow.document.write('<html><head><title>' + document.title + '</title> <meta name="viewport" content="width=device-width, initial-scale=1.0" />');
        mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></head><body >');
        mywindow.document.write(elem && elem.outerHTML);
        mywindow.document.close();
        mywindow.focus();
        setTimeout(() => {
            mywindow.print();
            setTimeout(() => {
                mywindow.close();
                if (callback) callback();
            }, 1000);
        }, 250);
    });
}



Array.prototype.printAC = (titre, idcmd, table) => {

    let style = `
        @media print {
            @page{
                size: landscape;
            }
        }
    `;

    [].print(
        <div className="ticker" style={{ width: 267 }}>
            <style>{style}</style>
            <table className="table table-bordered" >
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            {titre}
                        </th>
                    </tr>
                    <tr>
                        <td>Commande N° : </td>
                        <th>{idcmd}</th>
                    </tr>
                    <tr>
                        <td>Table : </td>
                        <th>{table}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

/*
Array.prototype.initPrinter = (dispatch) => {
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (jspmWSStatus()) {
            JSPM.JSPrintManager.getPrinters().then(myPrinters => {
                dispatch("printers", myPrinters)
            });
        }
    };
}


//Check JSPM WebSocket status
function jspmWSStatus() {
    if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open)
        return true;
    else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    }
    else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.BlackListed) {
        alert('JSPM has blacklisted this website!');
        return false;
    }
}

//Do printing...
Array.prototype.print1 = (dom, printername) => {
    window.document.querySelector("#printelm").style.display = "block";
    dom.style.backgroundColor = "#fff";
    setTimeout(() => {
        [].print2(dom, printername);
    }, 50);
}
Array.prototype.print2 = (dom, printername) => {
    if (jspmWSStatus()) {
        const callback = (canvas) => {
            window.document.querySelector("#printelm").style.display = "none";

            var url = canvas.toDataURL("image/png");
            console.log(url);
            window.open(url, '_blank');

            return;

            //Create a ClientPrintJob
            var cpj = new JSPM.ClientPrintJob();
            //Set Printer type (Refer to the help, there many of them!)
            if (!printername) {
                cpj.clientPrinter = new JSPM.DefaultPrinter();
            } else {
                cpj.clientPrinter = new JSPM.InstalledPrinter(printername);
            }
            //Set content to print...
            var b64Prefix = "data:image/png;base64,";
            var imgBase64DataUri = canvas.toDataURL("image/png");
            var imgBase64Content = imgBase64DataUri.substring(b64Prefix.length, imgBase64DataUri.length);

            var myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'myFileToPrint.png', 1);
            //add file to print job
            cpj.files.push(myImageFile);
            //Send print job to printer!

            cpj.sendToClient();
        };
        window.html2canvas(dom, { onrendered: callback, allowTaint: true, useCORS: true, backgroundColor: "rgba(255,255,255,1)" });
    }
}
*/