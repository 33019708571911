import React, { useState, useEffect } from 'react'

/**
* @author
* @function Crud
**/

const Crud = function (props) {
    let [state, setState] = useState({
        name: "test"
    });
    state.set = (obj = {}) => {
        state = { ...state, ...obj };
        setState(state);
    }

    const Bind = name => {
        return {
            value: state[name] || "",
            onChange: e => {
                state.set({ [name]: e.target.value || "" })
            }
        }
    }

    if (props.model) {
        props.model.rows = [];
    }

    useEffect(() => {
        state.Test = prs => {
            return (
                <input type="test" {...prs} {...Bind("name")} />
            )
        }
        state.set();
    }, [])

    if (!state.Test) {
        state.Test = prs => <input type="test" {...prs} {...Bind("aaa")} />
    }

    return (
        <div>
            <div>Crud</div>
            {props.schema.map((SchemaRow, i) => (
                <FrmText.InFilterView key={i} {...SchemaRow} {...Bind("testaa")} />
            ))}
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
    )
}

const getPropsTag = obj => {
    if (!obj) return {};
    let newObj = {};
    let list = ["value", "onChange", "style", "className", "id", "disabled", "checked", "selected"];
    for (let x in obj) {
        if (list.includes(x)) newObj[x] = obj[x];
    }
    return newObj;
}

Crud.Test = function (props) {
    return (
        <div>
            <Crud
                schema={[
                    {
                        title: "input A",
                        type: "text",
                        render: args => (
                            <select {...args}>
                                <option>Option 1</option>
                                <option>Option 2</option>
                            </select>
                        ),
                        inFilter: true,
                        asTitle: true,
                    },
                    {
                        title: "input B",
                        type: "text",
                        inFilter: true,
                        asTitle: true,
                    }
                ]}
            />
        </div>
    )
}


let CrudData = {
    rows: [],
    pagination: {
        page: 1,
        total: 0,
        nbrPage: 0,
        length: 10
    },
    staticFilter: {},
    filter: {},
    goToPage: page => { },
    loadData: filter => { },
    initItem: item => { },
    onInitItem: callback => { },
    schema: {},
}


const FrmItem = args => {
    return (
        <div>
            <label>{args.label || args.title}</label>
            <div {...getPropsTag(args)}>{args.children}</div>
        </div>
    )
}


const FrmText = args => {
    let frmitemargs = args.frmItem || {};
    if (args.label || args.title || args.titre)
        frmitemargs = { ...frmitemargs, label: args.label || args.title || args.titre }
    let args1 = { ...args, render: null }
    const Input = args.render ? <args.render {...getPropsTag(args1)} /> : <input type="text" {...getPropsTag(args1)} />
    return (
        <FrmItem {...frmitemargs} {...args1}>
            {Input}
        </FrmItem>
    )
}
FrmText.InFilterView = args => {
    return (
        <FrmText {...args} label={"in Filter => " + (args.label || args.title)} />
    )
}
FrmText.InTableView = args => { }
FrmText.InDetailView = args => { }

const FrmTextarea = args => { }

const FrmSelect = args => { }
const FrmLink = args => { }
const FrmDate = args => { }
const FrmTime = args => { }
const FrmEditor = args => { }
const FrmUploadFile = args => { }
const FrmNumber = args => { }
const FrmBoolean = args => { }





export default Crud