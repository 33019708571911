import React from 'react'

/**
* @author
* @function Prix
**/

const Prix = (props) => {
    let value = props.value || 0;
    value = value * 1;
    value = value.toFixed(2) * 1;
    value = value.toFixed(3);
    return (
        <span style={{ whiteSpace: 'nowrap' }}>{value} <sup><small>DT</small></sup></span>
    )
}


export default Prix