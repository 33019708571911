import React, { Component } from 'react'
import connectToStore from '../connect';
import CommandePrintViewCmd from './CommandePrintViewCmd';


/**
* @author
* @function Printer
**/



class Printer extends Component {
    list = [];
    state = { printing: null, printers: [] };
    componentDidMount() {
        const fbref = window.firebase.database().ref(`printer/${this.props.name}`);
        fbref.on("child_added", snap => {
            this.list.push(JSON.parse(JSON.stringify(snap.val())));
            fbref.child(snap.key).remove();
        });
        setInterval(() => {
            if (this.list.length) {
                if (this.state.printing) return;
                let doc = this.list.shift();
                [].print(<CommandePrintViewCmd logo={this.props.store.data.config.logo} item={doc} />, () => {
                    this.setState({ printing: null });
                });
                this.setState({ printing: doc });
            }
        }, 500);
    }
    componentWillUnmount() {

    }
    render() {
        return null;
    }
}

export default connectToStore(Printer);

