import React, { useState, useEffect } from 'react';
import connectToStore from '../connect';
import Layout from './../com/Layout';
import PrixCmd from './../com/PrixCmd';
import Dialog from './../com/Dialog';
import CommandePrintViewCmd from './CommandePrintViewCmd';
import { Button } from 'antd';

/**
 * @author
 * @function PublicMesAdresses
 **/

const PublicMesAdresses = (props) => {
  var state = useState({
    filter: { ...(props.filter || {}), uid: props.store.publicUser.uid },
  }).asPagination('loadMesAdresses');
  var { Table, loadData } = state;
  useEffect(loadData, []);
  const cols = [
    { name: 'id', titre: 'ID' },
    { titre: 'Date', render: (row) => row.createAtF },
    { titre: 'Adresse', render: (row) => row.adresse },
  ];
  var viewDialog = ''.$obj();
  const onClickAction = (name) => (row) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case 'edit':
        row.select();
        viewDialog.open();
        break;
      case 'delete':
        if (!window.confirm('Voulez vous vraiment supprimer cette adresse?'))
          return;
        props.store
          .api('deleteAdresse', {
            row: row,
            user: props.store.publicUser,
          })
          .then((res) => {
            loadData();
          });
        break;
      case 'add':
        state.set({ current: { adresse: '' } });
        viewDialog.open();
        break;
      case 'save':
        props.store
          .api('saveAdresse', {
            row: state.current,
            user: props.store.publicUser,
          })
          .then((res) => {
            if (res && res.errAdresse) {
              alert(res.errAdresse);
            } else {
              loadData();
              row.close();
            }
          });
        break;
    }
  };

  const setPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          props.store.api(
            'setPosition',
            {
              item: state.current,
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
            function () {
              alert('Géo Localisation Enregsitré');
              state.current.position = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              state.set();
            }
          );
        },
        function () {
          alert('Erreur Géo Localisation');
        },
        {
          enableHighAccuracy: true,
          maximumAge: 1000,
          timeout: 15000,
        }
      );
    }
  };

  return (
    <Layout>
      <hr />
      <div className="titleitemrow" style={{ justifyContent: 'space-between' }}>
        <h1>Mes Adresses</h1>
        <Button type="primary" onClick={onClickAction('add')}>
          Ajouter nouvelle Adresse
        </Button>
      </div>
      <Table
        cols={cols}
        noDataMessage="Aucun adresse trouvé"
        actions={[
          { name: 'eye', onClick: onClickAction('edit') },
          { name: 'trash', onClick: onClickAction('delete') },
        ]}
      />
      <Dialog
        modal={viewDialog}
        titre="Commande"
        actions={[
          {
            titre: 'Fermer',
            onClick: (dialog) => {
              loadData();
              dialog.close();
            },
          },
          { titre: 'Enregister', onClick: onClickAction('save') },
        ]}
      >
        {state.current ? (
          <div>
            <div className="form-group">
              <label>Adresse</label>
              <input
                className="form-control"
                placeholder="Adresse"
                value={state.current.adresse}
                onChange={(e) => {
                  state.current.adresse = e.target.value;
                  state.set();
                }}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-default" onClick={setPosition}>
                Localisez moi et définir la position de l'adresse
              </button>
              {state.current.position ? (
                <img
                  style={{ marginTop: 15 }}
                  alt="position"
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${state.current.position.lat},${state.current.position.lng}&zoom=14&size=466x300&maptype=roadmap&markers=color:red%7Clabel:A%7C${state.current.position.lat},${state.current.position.lng}&key=AIzaSyA0bbh9FJxy1aQ4BF5M8BiyyLFbzvtZX0A`}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </Dialog>
    </Layout>
  );
};

const SelectAdresse = connectToStore((props) => {
  var viewDialog = ''.$obj();
  let state = useState({
    adresse: '',
    filter: { ...(props.filter || {}), uid: props.store.publicUser.uid },
  }).asPagination('loadMesAdresses');
  let { loadData } = state;
  useEffect(loadData, []);
  const { value = null, onChange, className = 'form-control' } = props;
  const handleChange = (id) => {
    if (onChange) {
      onChange(id);
    }
  };

  useEffect(() => {
    if (state.rows.length && !value) {
      onChange(state.rows[0].id);
    }
  }, [state.rows, state.rows.length, onChange, value]);

  const saveAdresse = (dialog) => {
    props.store
      .api('saveAdresse', {
        row: { adresse: state.adresse },
        user: props.store.publicUser,
      })
      .then((res) => {
        if (res && res.errAdresse) {
          alert(res.errAdresse);
        } else {
          loadData();
          handleChange(res.id);
          dialog.close();
        }
      });
  };

  return (
    <div className="form-group">
      <label>Adresse de livraison</label>
      <div className="input-group mb-3">
        <select
          className={className}
          placeholder="Adresse"
          value={value || ''}
          onChange={(e) => handleChange(parseInt(e.target.value))}
        >
          {state.rows.map((item) => (
            <option key={item.id} value={item.id}>
              {item.adresse}
            </option>
          ))}
        </select>
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={(e) => {
              state.set({ adresse: '' });
              viewDialog.open();
            }}
          >
            Ajouter
          </button>
        </div>
      </div>
      <Dialog
        modal={viewDialog}
        titre="Commande"
        actions={[
          { titre: 'Annuler', onClick: (dialog) => dialog.close() },
          { titre: 'Enregister', onClick: saveAdresse },
        ]}
      >
        <div className="form-group">
          <label>Adresse</label>
          <input
            className="form-control"
            placeholder="Adresse"
            value={state.adresse}
            onChange={(e) => {
              state.adresse = e.target.value;
              state.set();
            }}
          />
        </div>
      </Dialog>
    </div>
  );
});

export { SelectAdresse };
export default connectToStore(PublicMesAdresses);
