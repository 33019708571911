import React, { useEffect, useState } from 'react';
import connectToStore from '../connect';
import PrixItem from '../com/PrixItem';
import PrixCmd from '../com/PrixCmd';

const PrintItem = ({ item }) => {
  return (
    <div className="print-item">
      <span className="label">
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          {item.qte || 1} : {item.titre}
        </div>
        <small>
          <ul>
            {(item.supplements || [])
              .filter((sup) => sup.checked)
              .map((sup) => {
                return <li key={'s' + sup.id}>{sup.titre_supplements}</li>;
              })}
            {(item.choix || [])
              .filter((sup) => sup.checked)
              .map((sup) => {
                return <li key={'s' + sup.id}>{sup.titre}</li>;
              })}
            {(item.supplementspayants || [])
              .filter((sup) => sup.checked)
              .map((sup) => {
                return <li key={'sp' + sup.id}>{sup.titre_supplements}</li>;
              })}
            {item.emplacement ? (
              <li key={'i' + item.emplacement}>{item.emplacement}</li>
            ) : null}
          </ul>
        </small>
      </span>
      <PrixItem item={item} />
    </div>
  );
};

const CommandePrintViewCmd = (props) => {
  return (
    <div className="ticker">
      <style>{`
*{
  font-weight:bold;
}
.ticker {
    max-width: 100mm;
    min-width: 60mm;
    display: flex;
    flex-direction: column;
    padding:20px 0;
  }
  
  .ticker>div.topticket {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .ticker img {
    width: 90px;
  }
  
  .print-item {
    display: flex;
    padding: 10px 0;
  }
  
  
  .print-item>.label {
    flex: 1;
  }
  
  .print-list>*+* {
    border-top: 1px solid #ccc;
  }
  
  .print-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  
  @media print {
    body {
      max-width: 400px !important;
      /* max-width: 100% !important; */
    }
  
    #root {
      min-height: auto !important;
      display: block !important;
      /* max-width: 100% !important; */
    }
  }
  
  .badgediv {
    position: absolute;
    top: 0;
    right: 35px;
    left: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 7px;
  }
  
  .infoservable {
    position: absolute;
    z-index: 100;
    background: #fff;
    top: 5px;
    right: 5px;
    padding: 2px;
    border-radius: 50%;
    height: 27px;
    line-height: 0;
    font-size: 24px;
  }
  
  .ucfirst {
    text-transform: lowercase;
  }
  
  .ucfirst:first-letter {
    text-transform: uppercase;
  }
  
  .table td,
  .table th {
    padding: 8px !important;
  }
            `}</style>
      <div className="topticket">
        <img src={props.logo.getUrl()} />
      </div>

      {props.facture ? (
        <div>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Facture N°:</th>
                <td>{props.facture.id}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: 70,
          }}
        >
          {props.item.num}
        </div>
      )}

      <table className="table table-bordered">
        <tbody>
          <tr>
            <th>Commande N° : </th>
            <th>{props.item.num}</th>
          </tr>
          {props.item.table ? (
            <tr>
              <th>Table : </th>
              <th>{props.item.table.titre}</th>
            </tr>
          ) : null}
          {props.item.dateformat ? (
            <tr>
              <th>Date : </th>
              <td>{props.item.dateformat}</td>
            </tr>
          ) : null}
          {props.item.name_user ? (
            <tr>
              <th>Utilisateur : </th>
              <td>{props.item.name_user}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <div className="print-list">
        {(props.item.list || []).map((item, i) => (
          <PrintItem key={i} item={item} />
        ))}
        <PrixCmd cmd={props.item} />
      </div>

      {props.facture ? (
        <div
          style={{
            margin: '15px 0',
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            border: '1px solid #ccc',
          }}
        >
          <span>Cachet et Signature</span>
        </div>
      ) : null}

      <div className="print-footer">Merci de votre visite.</div>
    </div>
  );
};

export default CommandePrintViewCmd;
