import React from 'react';
import Layout from '../com/Layout';
import connectToStore from '../connect';

/**
 * @author
 * @function DemandeAchat
 **/

const DemandeAchat = (props) => {
  let user = encodeURIComponent(JSON.stringify(props.store.user));
  return (
    <Layout titre="Demande d'achat">
      <style>{'.layoutcol{ padding-left:0;padding-right:0; }'}</style>
      <iframe
        src={'https://pro.koolfood.tn/web/demande-achat?user=' + user}
        frameBorder="0"
        style={{ wdith: '100%', flex: 1 }}
      ></iframe>
    </Layout>
  );
};

export default connectToStore(DemandeAchat);
