import React from 'react';

const FInput = (props) => {
  var Com = (args) => <div></div>;

  const emitChange = (value) => {
    if (props.onChange) {
      props.onChange({ target: { value: { value, type: props.type } } });
    }
  };
  let value = (props.value && props.value.value) || {};

  switch (props.type) {
    case 'select':
      var Com = (args) => (
        <select
          className="form-control"
          placeholder={props.label}
          value={args.value}
          onChange={(e) => emitChange(e.target.value)}
        >
          <option value="">Tout</option>
          {(props.options || [])
            .map((elm) =>
              typeof elm === 'string' ? { value: elm, label: elm } : elm
            )
            .map((elm, i) => (
              <option key={i} value={elm.value}>
                {elm.label}
              </option>
            ))}
        </select>
      );
      break;
    case 'periode':
      Date.prototype.toJSON1 = function () {
        if (!this.getFullYear()) return null;
        return (
          this.getFullYear() +
          '-' +
          ((this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1)) +
          '-' +
          (this.getDate() < 10 ? '0' : '') +
          this.getDate()
        );
      };
      var Com = (args) => {
        var value = args.value || { periode: 'p1' };
        value.periode = value.periode || 'p1';
        const setValue = (val) => {
          let now = new Date();
          switch (val) {
            case 'p0':
              emitChange({
                periode: val,
                de: null,
                a: null,
              });
              break;
            case 'p1':
              emitChange({
                periode: val,
                de: now.toJSON1().split('T')[0],
                a: now.toJSON1().split('T')[0],
              });
              break;
            case 'p2':
              emitChange({
                periode: val,
                de: new Date(new Date().setDate(now.getDate() - 1))
                  .toJSON1()
                  .split('T')[0],
                a: new Date(new Date().setDate(now.getDate() - 1))
                  .toJSON1()
                  .split('T')[0],
              });
              break;
            case 'p3':
              function getMondayOfCurrentWeek(d) {
                var day = d.getDay();
                return new Date(
                  d.getFullYear(),
                  d.getMonth(),
                  d.getDate() + (day == 0 ? -6 : 1) - day
                );
              }
              function getSundayOfCurrentWeek(d) {
                var day = d.getDay();
                return new Date(
                  d.getFullYear(),
                  d.getMonth(),
                  d.getDate() + (day == 0 ? 0 : 7) - day
                );
              }

              emitChange({
                periode: val,
                de: getMondayOfCurrentWeek(now).toJSON1().split('T')[0],
                a: getSundayOfCurrentWeek(now).toJSON1().split('T')[0],
              });

              break;
            case 'p4':
              function getFirstDayOfCurrentMonth(d) {
                return new Date(d.getFullYear(), d.getMonth(), 1);
              }
              function getLastDayOfCurrentMonth(d) {
                return new Date(d.getFullYear(), d.getMonth() + 1, 0);
              }
              emitChange({
                periode: val,
                de: getFirstDayOfCurrentMonth(now).toJSON1().split('T')[0],
                a: getLastDayOfCurrentMonth(now).toJSON1().split('T')[0],
              });
              break;
            case 'p5':
              emitChange({
                periode: val,
                de: now.getFullYear() + '-01-01',
                a: now.getFullYear() + '-12-31',
              });
              break;
            case 'p6':
              emitChange({
                ...value,
                periode: val,
              });
              break;
          }
        };
        const updateDate = (name) => (e) => {
          let d = e.target.value;
          emitChange({
            ...value,
            [name]: new Date(d).toJSON1(),
          });
        };
        return (
          <div>
            <div
              style={{
                display: 'inline-grid',
                gridAutoFlow: 'column',
                gridGap: 10,
              }}
            >
              <select
                className="form-control"
                value={value.periode}
                placeholder={props.label}
                onChange={(e) => setValue(e.target.value)}
              >
                <option value="p0">Tout</option>
                <option value="p1">Aujourd'hui</option>
                <option value="p2">Hier</option>
                <option value="p3">Cette semaine</option>
                <option value="p4">Ce mois</option>
                <option value="p5">Cette Année</option>
                <option value="p6">Personnaliser</option>
              </select>
              {value.periode == 'p6' ? (
                <>
                  <div style={{ marginTop: -30 }}>
                    <label>De</label>
                    <input
                      style={{ width: 166 }}
                      className="form-control"
                      type="date"
                      value={value.de}
                      onChange={updateDate('de')}
                    />
                  </div>
                  <div style={{ marginTop: -30 }}>
                    <label>a</label>
                    <input
                      style={{ width: 166 }}
                      className="form-control"
                      type="date"
                      value={value.a}
                      onChange={updateDate('a')}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        );
      };
      break;
    default:
      break;
  }

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <Com value={value} onChange={props.onChange} />
    </div>
  );
};

export default FInput;
