import React, { Component } from 'react';
import connectToStore from '../connect';
import Dialog from '../com/Dialog';
import { Button } from 'antd';

class PrintDialog extends Component {
  dialog = {};
  componentDidMount() {
    this.props.store.printDialog = this.props.store.printDialog || {};
    this.props.store.printDialog.open = () =>
      this.dialog && this.dialog.open && this.dialog.open();
  }

  saveUserImprimer = (item) => {
    this.props.store.api('saveUserImprimer', { item }).then((res) => {
      if (this.dialog && this.dialog.close) {
        this.dialog.close();
      }
    });
  };

  render() {
    const { store } = this.props;
    if (!store.user) return null;
    return (
      <Dialog
        modal={this.dialog}
        titre="Imprimante"
        actions={[
          {
            titre: 'Annuler',
            onClick: (e) => {
              this.dialog.close();
            },
          },
          {
            titre: 'Valider',
            primary: true,
            onClick: (e) => {
              this.dialog.close();
            },
          },
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {(store.data.typepreparateurs || []).map((item) => {
            return (
              <Button
                type={item.titre === store.user.imprimer ? 'primary' : ''}
                style={{ margin: 8 }}
                size="large"
                onClick={(e) => this.saveUserImprimer(item)}
              >
                {item.titre}
              </Button>
            );
          })}
        </div>
      </Dialog>
    );
  }
}

export default connectToStore(PrintDialog);
