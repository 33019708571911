import React from 'react';
import { Spin } from 'antd';

const Loading = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99,
        background: 'rgba(255,255,255,0.5)',
      }}
    >
      <Spin />
    </div>
  );
};

export default Loading;
