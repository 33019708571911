import React, { useState, useEffect } from 'react'
import "./Atom.css"
// import AtomList from './Com/AtomList';
import api from '../store/api';


function Atom(props) {
    let state = useState({ loading: false }).toBindState(props);
    if (props.actions) {

    }
    // if (props.useApi && props.name) {
    //     api(props.useApi, { name: props.name }).then(rep => {

    //     })
    // }
    return (
        <div className="atom-main">
            <AtomList {...state.bind("value", { sendBind: true, onChange: e => { } })} />
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
    )
}



const AtomList = props => {
    return (
        <div>
            <input type="text" {...props.bind("title")} />
            <pre>{JSON.stringify(props, null, 2)}</pre>
        </div>
    );
}



export default Atom;