import React from 'react'
import Prix from './Prix';

/**
* @author
* @function PrixItem
**/

const PrixItem = ({ item }) => {
    let total = item.prix_vente_u_ttc * (item.qte || 1);
    (item.supplementspayants || [])
        .filter(sup => sup && sup.checked && sup.prix > 0)
        .map(sup => {
            total += sup.prix * (item.qte || 1);
        });
    return (
        <Prix value={total} />
    )
}


export default PrixItem