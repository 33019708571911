import React, { useState, useEffect } from 'react';
import Layout from '../com/Layout';
import connectToStore from '../connect';
import { Tabs, Table, Button, Icon, Row, Col, Spin } from 'antd';
import PrixCmd from '../com/PrixCmd';
import CommandePrintViewCmd from './CommandePrintViewCmd';
import Dialog from '../com/Dialog';
const { TabPane } = Tabs;

/**
 * @author
 * @function Encaissement
 **/

const Encaissement = (props) => {
  var state = useState({
    loading: false,
    filter: { encaissement: false },
    args: { length: 10000 },
  }).asPagination('loadCommandes');
  useEffect(state.loadData, []);
  var viewDialog = ''.$obj();
  const initItem = (item) => {
    let total =
      item.list
        .reduce((t, e) => {
          let totalsup = 0;
          (item.supplementspayants || [])
            .filter((sup) => sup && sup.checked && sup.prix > 0)
            .map((sup) => {
              totalsup += sup.prix;
            });
          return t + (e.prix_vente_u_ttc * 1 + totalsup) * e.qte * 1;
        }, 0)
        .toFixed(2) * 1;

    let remise = 0;
    if (item.remise_type && item.remise_value) {
      switch (item.remise_type) {
        case '%':
          remise = (item.remise_value * total) / 100;
          total -= (item.remise_value * total) / 100;
          break;
        case 'DT':
          remise = item.remise_value;
          total -= item.remise_value;
          break;
      }
    }
    item.total = total;

    item.total = item.total || 0;
    item.selectToggle = () => {
      item.selected = !item.selected;
      state.set();
    };
  };
  state.rows.map(initItem);
  state.all = !state.rows.find((elm) => !elm.selected);
  const selectAll = () => {
    state.all = !state.all;
    state.rows.map((elm) => {
      elm.selected = state.all;
    });
    state.set({});
  };

  const encaissement = (serveur) => (e) => {
    let ids = state.rows
      .filter((row) => row.type_user == serveur.is && row.id_user == serveur.id)
      .filter((row) => row.selected)
      .map((row) => row.id);
    state.set({ loading: true });
    props.store
      .api('encaissement', {
        serveur,
        ids,
      })
      .then((res) => {
        state.set({ loading: false });
        state.loadData();
      });
  };

  const columns = [
    {
      title: 'N°',
      dataIndex: 'num',
      width: '1%',
    },
    {
      title: 'Table',
      dataIndex: 'table',
      render: (val, item) => item.table && item.table.titre,
    },
    {
      title: 'Etat',
      dataIndex: 'state',
      render: (val, item) => (
        <div style={{ color: item.etat == 1 ? 'green' : '#000' }}> {val} </div>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '1%',
      render: (val, item) => <PrixCmd cmd={item} priceOnly={true} />,
    },
    {
      title: (args) => (
        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            style={{ display: 'flex' }}
            type={state.all ? 'primary' : 'normal'}
            onClick={selectAll}
          >
            <Icon type="plus" />
          </Button>
        </div>
      ),
      dataIndex: 'encaissement',
      width: '1%',
      render: (val, item) => (
        <div style={{ display: 'flex' }}>
          <Button
            size="small"
            style={{ display: 'flex' }}
            type={item.selected ? 'primary' : 'normal'}
            onClick={item.selectToggle}
          >
            <Icon type="plus" />
          </Button>
        </div>
      ),
    },
  ];
  const columns1 = columns.map((col) => {
    if (col.dataIndex === 'encaissement') {
      return {
        ...col,
        title: '',
      };
    }
    return col;
  });

  const listusers = [
    ...props.store.data.serveurs.map((item) => ({ ...item, is: 'Serveur' })),
    ...props.store.data.responsables.map((item) => ({
      ...item,
      is: 'Responsable Front',
    })),
  ];

  return (
    <Layout titre="Encaissement">
      <Spin spinning={state.loading || state.$loading}>
        <Tabs defaultActiveKey="0">
          {listusers.map((serveur, i) => (
            <TabPane
              tab={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{serveur.nom}</span>
                  <span style={{ margin: '0 5px' }}> : </span>
                  <span>
                    {state.rows
                      .filter(
                        (row) =>
                          row.type_user == serveur.is &&
                          row.id_user == serveur.id
                      )
                      .reduce((t, cmd) => {
                        let list = (cmd || {}).list || [];
                        let total = list.reduce((total, item) => {
                          let totalsup = 0;
                          (item.supplementspayants || [])
                            .filter((sup) => sup && sup.checked && sup.prix > 0)
                            .map((sup) => {
                              totalsup += sup.prix;
                            });
                          return (
                            total +
                            (item.prix_vente_u_ttc + totalsup) * (item.qte || 1)
                          );
                        }, 0);
                        let remise = 0;
                        if (cmd.remise_type && cmd.remise_value) {
                          switch (cmd.remise_type) {
                            case '%':
                              remise = (cmd.remise_value * total) / 100;
                              total -= (cmd.remise_value * total) / 100;
                              break;
                            case 'DT':
                              remise = cmd.remise_value;
                              total -= cmd.remise_value;
                              break;
                          }
                        }
                        cmd.total_final = total;
                        return t + total;
                      }, 0)
                      .toFixed(2) + '0'}{' '}
                    <small>
                      <sup>DT</sup>
                    </small>{' '}
                  </span>
                </div>
              }
              key={'' + i}
            >
              <Row gutter={32}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Table
                    dataSource={state.rows.filter(
                      (row) =>
                        row.type_user == serveur.is && row.id_user == serveur.id
                    )}
                    columns={columns}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Table
                    dataSource={state.rows
                      .filter(
                        (row) =>
                          row.type_user == serveur.is &&
                          row.id_user == serveur.id
                      )
                      .filter((row) => row.selected)}
                    columns={columns1}
                  />
                  <hr />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <table
                      className="table table-striped table-bordered"
                      style={{ width: 'auto', margin: 0 }}
                    >
                      <tbody>
                        <tr>
                          <th>Total</th>
                          <th>
                            {state.rows
                              .filter(
                                (row) =>
                                  row.type_user == serveur.is &&
                                  row.id_user == serveur.id
                              )
                              .filter((row) => row.selected)
                              // .map(e => )
                              .reduce((t, e) => t + e.total, 0)
                              .toFixed(2) + '0'}
                            <small>
                              <sup>DT</sup>
                            </small>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <span style={{ flex: 1 }}></span>
                    <Button
                      type="primary"
                      disabled={
                        state.rows
                          .filter(
                            (row) =>
                              row.type_user == serveur.is &&
                              row.id_user == serveur.id
                          )
                          .filter((row) => row.selected).length === 0
                      }
                      onClick={encaissement(serveur)}
                    >
                      Encaisser
                    </Button>
                  </div>
                  <hr />
                </Col>
              </Row>
            </TabPane>
          ))}
        </Tabs>
      </Spin>
      <Dialog
        modal={viewDialog}
        titre="Commande"
        actions={[{ titre: 'Fermer', onClick: (dialog) => dialog.close() }]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CommandePrintViewCmd
            logo={props.store.data.config.logo}
            item={state.current}
          />
        </div>
      </Dialog>
    </Layout>
  );
};

export default connectToStore(Encaissement);
