import React, { useState, useEffect } from 'react'
import Menu from '../com/Menu';
import Layout from '../com/Layout';
import connectToStore from '../connect';
import CommandeForm from '../com/CommandeForm';
import CommandeList from '../com/CommandeList';
import Actions from '../com/Actions';
import { Button } from 'antd';

/**
* @author
* @function Commande
**/



const Commande = props => {

    // props.layout.set({ hideMenu: true });

    return (
        <Layout views={Views} view={props.view || ""} >
            <Actions list={props.actions || []} />
        </Layout>
    )
}

const CommandeNew = connectToStore(props => {
    var data = props.createState(useState({ etat: 0 }))
    return <CommandeForm cmd={data} actions={props.actions} />
})

const CommandeEdit = connectToStore(props => {
    var data = props.createState(useState({}))
    useEffect(() => {
        data.loadFrom("getCommande", { id: props.match.params.id })
    }, [props.match.params.id])
    var { LoadingCom } = data;


    useEffect(() => {
        props.store.commandes.filter(cmd => cmd.newupdate && data.id == cmd.id).map(cmd => {
            cmd.newupdate = false;
            data.list = cmd.list;
            data.set({});
        });
        for (let i = 0; i < props.store.commandes.length; i++) {
            props.store.commandes[i].newupdate = false;
        }
        props.dispatch("newupdate", false);
    }, [props.store.commandes.map(cmd => cmd.id + ':' + (cmd.newupdate ? 't' : 'f')).join("|")])


    return (
        <div>
            <LoadingCom />
            <CommandeForm cmd={data} actions={props.actions} />
        </div>
    )
})


const CommandeTablette = connectToStore(props => {
    var data = props.createState(useState({}))
    useEffect(() => {
        data.loadFrom("getCommandeTablette", { id: props.store.user.id })
    }, [props.store.user.id, props.store.commandes.length]);
    const loadCmd = e => {
        data.loadFrom("getCommandeTablette", { id: props.store.user.id })
    }
    var { LoadingCom } = data;


    useEffect(() => {
        props.store.commandes.filter(cmd => cmd.newupdate && data.id == cmd.id).map(cmd => {
            cmd.newupdate = false;
            data.list = cmd.list;
            data.set({});
        });
        for (let i = 0; i < props.store.commandes.length; i++) {
            props.store.commandes[i].newupdate = false;
        }
        props.dispatch("newupdate", false);
    }, [props.store.commandes.map(cmd => cmd.id + ':' + (cmd.newupdate ? 't' : 'f')).join("|")])

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <LoadingCom />
            {props.store.commandes.length ? <CommandeForm cmd={data} actions={props.actions} tablette={true} /> : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                    <Button type="primary" onClick={loadCmd}>{props.store.user.nom}</Button>
                </div>
            )}
        </div>
    )
})


const Views = {
    "new": {
        titre: "Nouveau commande",
        Com: CommandeNew
    },
    "edit": {
        titre: "Mise a jour commande",
        Com: CommandeEdit
    },
    "tablette": {
        titre: "Tablette",
        Com: CommandeTablette
    },
    "list": {
        titre: "Commandes en cours",
        Com: args => <CommandeList {...args} filter={{ etat: 0 }} view="" />
    },
    "historique": {
        titre: "Historique commandes",
        Com: args => <CommandeList {...args} filter={{ historique: true }} view="" />
    },


    "list-serveur": {
        titre: "Commandes en cours",
        Com: args => <CommandeList {...args} filter={{ etat: 0 }} view="serveur" />
    },

    "list-caissier": {
        titre: "Commandes en cours",
        Com: args => <CommandeList {...args} filter={{ etat: 0 }} view="caissier" />
    },

    "list-resp": {
        titre: "Commandes en cours",
        Com: args => <CommandeList {...args} filter={{ etat: 0 }} view="resp" />
    },


    "list-poste": {
        titre: "Commandes en cours",
        Com: args => <CommandeList {...args} filter={{ etat: 0 }} view="poste" />
    },
    "historique-poste": {
        titre: "Historique commandes",
        Com: args => <CommandeList {...args} filter={{ historique: true }} />
    }
}

export default connectToStore(Commande)