import React, { useState } from 'react'
import Menu from '../com/Menu';
import connectToStore from '../connect';

/**
* @author
* @function DefaultLayout
**/

const DefaultLayout = props => {
    let [state, setState] = useState(props.defaultState || {});
    if (props.actions) {
        props.actions.menuVisible = v => setState({ ...state, hideMenu: !v })
        props.actions.set = (obj = {}) => setState({ ...state, ...obj })
    }
    return (
        <div className="layoutcol cmdcontent">
            {(state.hideMenu || (props.store.user && props.store.user.role == "tablette")) ? null : <Menu offline={state.offline} />}
            {props.children}
        </div>
    )
}


export default connectToStore(DefaultLayout);