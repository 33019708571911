import React, { useState } from 'react';
import connectToStore from '../connect';
import Layout from './../com/Layout';
import CommandeForm from './../com/CommandeForm';
/**
 * @author
 * @function PublicCmd
 **/

const PublicCmd = (props) => {
  var data = props.createState(useState({ etat: 0 }));

  return (
    <Layout>
      <CommandeForm cmd={data} actions={props.actions} isPublic={true} />
    </Layout>
  );
};

export default connectToStore(PublicCmd);
