import React from 'react';
import { createStore } from 'redux';
import config from '../config';
import { Redirect } from 'react-router-dom';
import Loading from '../com/Loading';
import { Modal, Button, Form } from 'react-bootstrap';
import Dialog from '../com/Dialog';
import Paginations from '../com/Pagination';
import { Switch, Route } from 'react-router';
import RouteList from './RouteList';
import './ArrayPrototype';

Array.prototype.asPagination = function (apiEndPoint = null, options = {}) {
  let state = initialState.createState(this);
  state = state || {};
  state.options = options || {};
  state.data = state.data || [];
  state.rows = state.rows || [];
  state.filter = state.filter || {};
  state.args = state.args || {};
  state.args.page = state.args.page || 1;
  state.apiEndPoint = state.apiEndPoint || apiEndPoint;
  return state;
};

// Array.prototype.toState = function () {
//     let state = initialState.createState(this);
//     return state;
// }

String.prototype.$obj = function (obj = {}) {
  let $val = obj || {};
  $val.$$eventslist = [];
  $val.$on = (event, callback) => $val.$$eventslist.push({ event, callback });
  $val.$emit = (event, data = {}) =>
    $val.$$eventslist
      .filter((ev) => ev.event === event)
      .map((ev) => ev.callback(data));
  return $val;
};

function getCom(Com) {
  if (Com && Com.$$typeof && typeof Com.$$typeof === 'symbol') {
    let ComType = Com.type;
    let ComProps = Com.props;
    Com = (args) => <ComType {...ComProps} {...args} />;
  }
  return Com;
}

var initialState = {
  config,
  initLoading: true,
  printDialog: {},
  data: {},
  test: 'ok',
  user: null,
  access_token: null,
  RouteList: () => new RouteList(),
  logout: () => initialState.api('logout'),
  error: false,
  publicUser: null,
  publicUserUpdated: false,
  audioNotification: () => {
    let audio = new Audio(config.audioNotification);
    audio.play();
  },
  api: (name, data = {}, callback) => {
    let url = '';

    if (window.location.origin.match(/localhost|192\.168|b2c2/)) {
      url = 'https://erp.koolfood.tn/web/?api=';
    } else {
      url = 'https://pro.koolfood.tn/web/?api=';
    }

    url += name;

    if (initialState.access_token) {
      url +=
        (url.match(/\?/) ? '&' : '?') +
        'access_token=' +
        initialState.access_token;
    }
    if (config.appKey) {
      url += (url.match(/\?/) ? '&' : '?') + 'app_key=' + config.appKey;
    }
    url += (url.match(/\?/) ? '&' : '?') + 'hn=' + window.location.hostname;

    if (window.location.origin.match(/localhost|192\.168|b2c2/)) {
      url += '&env=dev';
    } else {
      url += '&env=prod';
    }

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(initialState.apiResponse(callback))
      .catch((error) => {
        console.log('api Call error :', config.api + name);
        console.error(error);
      });
  },
  redirectTo: null,
  to: (path) => dispatch('to', path),
  redirect: () => {
    var to = store.redirectTo;
    dispatch('to', null);
    return <Redirect to={to} />;
  },
  apiResponse: (callback) => (rep) => {
    const headCallback = (headers) => {
      headers.forEach((head) => {
        switch (head.event) {
          case 'log':
            console.log.apply(null, [head.data]);
            break;
          case 'alert':
            alert(head.data);
            break;
          case 'eval':
            eval(head.data);
            break;
          case 'reload':
            window.location.reload();
            break;
          default:
            // setTimeout(() => {
            //     dispatch(head.event, head.data)
            // }, 10)
            dispatch(head.event, head.data, head.delay || 0);
            break;
        }
      });
    };
    if (rep && 'body' in rep && 'header' in rep) {
      headCallback(rep.header);
      if (callback) callback(rep.body);
      return rep.body;
    } else if (rep && 'master' in rep && 'slave' in rep) {
      headCallback(rep.slave);
      if (callback) callback(rep.master);
      return rep.master;
    }
    if (callback) callback(rep);
    return rep;
  },
  set: (obj) => {
    dispatch('setState', obj);
  },
  createState: ([data, setData1], { spinner = true } = {}) => {
    data.$ctrl = data.$ctrl || {};
    var $notmounted = false;
    data.cleanup = () => {
      $notmounted = false;
      return () => {
        $notmounted = true;
      };
    };
    const setData = (obj) => {
      if ($notmounted) return;
      setData1(obj);
    };
    data.bind = (name, options = {}) => {
      let defaultValue = (options || {}).default || '';
      let target = (options || {}).target || data;
      let names = name.split('.');
      let value = names.reduce((val, n) => {
        if (val && n in val) {
          return val[n];
        }
        return false;
      }, target);
      return {
        value: value || defaultValue,
        onChange: (e) => {
          let listeval = [];
          let lastprop = names.pop();
          let path = 'target';
          if (names.length > 0) {
            path = names.reduce((ac, n) => {
              listeval.push(ac + '.' + n + '=' + ac + '.' + n + ' || {}');
              return ac + '.' + n;
            }, 'data');
            eval(listeval.join(';'));
          }
          eval(path + '.' + lastprop + ' = e.target.value || defaultValue');
          setData({ ...data });
          if (options && options.onChange) options.onChange(e);
        },
        onKeyDown: (e) => {
          if (options && options.onKeyDown) options.onKeyDown(e);
          if (e.keyCode == 13) {
            if (options && options.onEnter) {
              e.preventDefault();
              options.onEnter(e);
            }
          }
        },
      };
    };

    data.inputText = (name, label, options = {}) => {
      let classname = '';
      if ((data.$ctrl[name] || {}).success) {
        classname = ' is-valid';
      } else if ((data.$ctrl[name] || {}).error) {
        classname = ' is-invalid';
      }
      return (
        <div className="form-group">
          <label>{label}</label>
          {options.textarea ? (
            <textarea
              rows={options.textarea}
              className={'form-control' + classname}
              {...(options.attrs || {})}
              {...data.bind(name, { target: options.target })}
            />
          ) : (
            <input
              type={options.type || 'text'}
              className={'form-control' + classname}
              {...(options.attrs || {})}
              {...data.bind(name, { target: options.target })}
            />
          )}
          {(data.$ctrl[name] || {}).error &&
          (data.$ctrl[name] || {}).error !== true ? (
            <div className="invalid-feedback">
              {(data.$ctrl[name] || {}).error}
            </div>
          ) : null}
        </div>
      );
    };

    data.set = (obj) => {
      data = { ...data, ...obj };
      setData(data);
      dispatch('actstats');
    };

    data.submitTo = (name) => {
      return (e) => {
        e.preventDefault();
        data.set({ $ctrl: {}, $loading: true });
        if (typeof name === 'function') {
          name(data).then((res) => {
            data.set({ $loading: false });
          });
        } else {
          return initialState.api(name, data).then((rep) => {
            data.set({ $ctrl: (rep || {}).ctrl || {}, $loading: false });
          });
        }
      };
    };

    data.form = (apiname, content) => {
      return (
        <div className="position-relative">
          {data.$loading && spinner ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 99,
                backgroundColor: 'rgba(255,255,255,0.5)',
              }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null}
          <form onSubmit={data.submitTo(apiname)}>{content}</form>
        </div>
      );
    };

    const initItem = (item) => {
      item.select = () => {
        data.set({ current: item });
      };
      return item;
    };

    data.loadFrom = (apiEndPoint, filter = {}) => {
      data.set({ $loading: true });
      initialState
        .api(apiEndPoint || data.apiEndPoint, {
          ...(data.filter || {}),
          ...filter,
        })
        .then((res) => {
          data.set({
            ...res,
            filter: { ...(data.filter || {}), ...filter },
            $loading: false,
          });
        });
      return () => {};
    };

    data.loadData = (args = {}, apiEndPoint = false) => {
      data.set({
        $loading: true,
        rows: data.rows || [],
        apiEndPoint: apiEndPoint || data.apiEndPoint,
      });
      initialState
        .api(apiEndPoint || data.apiEndPoint, {
          ...(data.args || {}),
          ...args,
          filter: { ...(data.filter || {}), ...(args.filter || {}) },
        })
        .then((res) => {
          if (res && res.data) {
            let rows = res.data.map(initItem);
            delete res.data;
            data.set({
              ...res,
              rows,
              args: { ...(data.args || {}), ...args },
              filter: { ...(data.filter || {}), ...(args.filter || {}) },
              $loading: false,
            });
          }
        });
      let options = data.options || {};
      if (data._timersync) clearInterval(data._timersync);
      if (options.sync) {
        data._timersync = setInterval(() => {
          if (data.$istimersync) {
            data.$istimersync += 1;
            if (data.$istimersync > 3) {
              data.$istimersync = false;
            }
            if (data.$istimersync) return;
          }
          data.set({ $istimersync: 1 });
          initialState
            .api(apiEndPoint || data.apiEndPoint, {
              ...(data.args || {}),
              ...args,
              filter: { ...(data.filter || {}), ...(args.filter || {}) },
            })
            .then((res) => {
              if (res && res.data) {
                let rows = res.data.map(initItem);
                delete res.data;
                data.set({
                  ...res,
                  rows,
                  args: { ...(data.args || {}), ...args },
                  filter: { ...(data.filter || {}), ...(args.filter || {}) },
                });
              }
              data.set({ $istimersync: false });
            })
            .catch((error) => data.set({ $istimersync: false }));
        }, options.sync);
        data.set({});
        return () => {
          if (data._timersync) clearInterval(data._timersync);
        };
      }

      return () => {};
    };
    data.goToPage = (page) => data.loadData({ page });
    data.LoadingCom = (props) => (data.$loading ? <Loading /> : null);
    data.PaginateCom = (props) => {
      return <Paginations {...props} />;
    };
    data.Modal = (props) => {
      let handleClose = (e) => {
        data.set({ ['openModal_' + props.name]: false });
      };
      let visible = data['openModal_' + props.name] || false;
      let { size } = props;
      let incprops = { size };
      let actions = props.actions || [];
      let modalState = data['stateModal_' + props.name] || {};
      return (
        <div>
          <Modal show={visible} onHide={handleClose} {...incprops}>
            {modalState.loading ? <Loading /> : null}
            {props.titre || props.title ? (
              <Modal.Header closeButton>
                <Modal.Title>{props.titre || props.title}</Modal.Title>
              </Modal.Header>
            ) : null}
            <Modal.Body>{props.children}</Modal.Body>
            {actions.length ? (
              <Modal.Footer>
                {actions.map((action, i) => (
                  <Button
                    key={i}
                    variant={action.primary ? 'primary' : 'secondary'}
                    onClick={action.onClick}
                  >
                    {action.titre || action.title}
                  </Button>
                ))}
              </Modal.Footer>
            ) : null}
          </Modal>
        </div>
      );
    };
    /*
                data.Modal.open = modalName => {
                    data.set({ ['openModal_' + modalName]: true });
                }
        
                data.Modal.close = modalName => {
                    data.set({ ['openModal_' + modalName]: false });
                }
        
                data.Modal.toggle = modalName => {
                    data.set({ ['openModal_' + modalName]: !data['openModal_' + modalName] });
                }
        
                data.Modal.setState = (modalName, state = {}) => {
                    data.set({ ['stateModal_' + modalName]: { ...(data['stateModal_' + modalName] || {}), ...state } });
                }
        
                data.Modal.getState = (modalName, state = {}) => {
                    return { ...(data['stateModal_' + modalName] || {}), ...state };
                }
        */
    data.LayoutData = (props) => {
      const { LoadingCom, PaginateCom } = data;
      return (
        <div className="position-relative">
          <LoadingCom />
          <div>{props.children}</div>
          <div style={{ display: 'flex' }}>
            <PaginateCom
              page={data.page}
              nbrPages={data.nbrPage}
              goToPage={data.goToPage}
            />
            <div style={{ flex: 1 }}></div>
            <div
              style={{
                display: 'grid',
                gridGap: 5,
                gridAutoFlow: 'column',
                alignItems: 'flex-start',
              }}
            >
              {props.footerRight}
            </div>
          </div>
        </div>
      );
    };
    data.Table = (props) => {
      const { LayoutData } = data;
      let cols = props.cols || [];
      let actions = props.actions || [];
      let rows = data.rows || [];
      let noDataMessage = props.noDataMessage || null;
      return (
        <LayoutData footerRight={props.footerRight}>
          <table className="table table-striped">
            <tbody>
              <tr>
                {cols.map((col, i) => (
                  <th key={i} style={col.thStyle}>
                    {col.titre}
                  </th>
                ))}
                <td style={{ width: 1 }}></td>
              </tr>
              {!data.$loading && noDataMessage && !rows.length ? (
                <tr>
                  <td colSpan={cols.length + 1}>
                    <div
                      style={{
                        minHeight: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {noDataMessage}
                    </div>
                  </td>
                </tr>
              ) : null}
              {rows.map(initItem).map(function (row, j) {
                return (
                  <tr key={j}>
                    {cols.map((col, i) => (
                      <td key={i}>
                        {col.render ? col.render(row) : row[col.name]}
                      </td>
                    ))}
                    <td style={{ whiteSpace: 'nowrap', zoom: 0.7, padding: 9 }}>
                      {actions.map((action, i) => {
                        if (action.render) return action.render(row, i);
                        switch ((action.name || '').trim().toLowerCase()) {
                          case 'edit':
                          case 'eye':
                          case 'trash':
                          case 'print':
                            if (!action.icon)
                              action.icon =
                                'fa fa-' +
                                (action.name || '').trim().toLowerCase();
                            break;
                        }
                        return (
                          <button
                            key={i}
                            className={
                              'btn btn-ms ' + (action.btnClass || 'btn-dark')
                            }
                            style={{ margin: '0 3px' }}
                            onClick={(e) =>
                              action.onClick ? action.onClick(row) : null
                            }
                          >
                            <i className={action.icon}></i> {action.text || ''}
                          </button>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </LayoutData>
      );
    };

    data.Filter = (props) => {
      return <div>Filter</div>;
    };

    data.Filter.Row = (props) => {
      return (
        <div className="filter-row" key="filter-row">
          {props.children}
        </div>
      );
    };

    data.Filter.Text = (props) => {
      return (
        <div className="form-group">
          <label>{props.titre || props.title}</label>
          <input
            type="text"
            className="form-control"
            placeholder={props.titre || props.title}
            {...data.bind('filter.' + props.name)}
          />
        </div>
      );
    };
    return data;
  },
  allowSync: true,
  syncEach: (delay, onSync = null) => {
    let syncLoading = 0;
    let interval = setInterval(() => {
      let ss = store.getState();
      if (!ss.allowSync || !ss.user || !ss.user.id) return;
      if (syncLoading) {
        syncLoading += 1;
        if (syncLoading > 3) {
          syncLoading = 0;
        }
      }
      if (!syncLoading) {
        syncLoading = 1;
        initialState
          .api('sync', onSync ? onSync(ss) || {} : {})
          .then((res) => (syncLoading = 0));
      }
    }, delay);
    return () => clearInterval(interval);
  },
  commandes: [],
  activecommandes: [],
  notificationCommandes: [],
  stats: [],
  nbralert: 0,
  nbralertserveur: 0,
  printers: [],
};

function mainReducer(state = initialState, action) {
  switch (action.type) {
    case 'init':
      state.initLoading = true;
      // [].initPrinter(dispatch);
      state.api('init').then((rep) => {
        if (rep) {
          dispatch('initdata', rep);
        } else {
          dispatch('error', 'Erreur de connexion au serveur');
        }
      });
      break;
    case 'printers':
      state.printers = action.payload || [];
      break;
    case 'error':
      state.error = action.payload || 'Erreur';
      state.initLoading = false;
      break;
    case 'initdata':
      state.initLoading = false;
      state.data = action.payload || {};
      dispatch('actstats', null, 1);
      break;
    case 'setUser':
      state.user = action.payload;
      dispatch('actstats', null, 1);
      break;
    case 'setAccessToken':
      state.access_token = action.payload;
      dispatch('actstats', null, 1);
      break;
    case 'to':
      state.redirectTo = action.payload;
      break;
    case 'logout':
      state.user = null;
      dispatch('actstats', null, 1);
      break;
    case 'setState':
      state = { ...state, ...(action.payload || {}) };
      break;
    case 'setTableState':
      break;
    case 'allowSync':
      state.allowSync = !!action.payload;
      break;
    case 'newupdate':
      for (let i = 0; i < state.commandes.length; i++) {
        state.commandes[i].newupdate = false;
      }
      break;
    case 'syncCommande':
      let cmd = action.payload.cmd;
      switch (action.payload.action) {
        case 'add':
          if (!state.commandes.find((elm) => elm.id == cmd.id)) {
            cmd.alerttime = new Date().getTime();
            state.commandes.push(cmd);
          }
          break;
        case 'update':
          let finded = false;
          cmd.newupdate = true;
          cmd.alerttime = new Date().getTime();
          state.commandes = state.commandes.map((elm) => {
            if (elm.id == cmd.id) {
              finded = true;
              return cmd;
            }
            return elm;
          });
          if (!finded) {
            state.commandes.push(cmd);
          }
          if (!state.notificationCommandes.find((a) => a.id === cmd.id)) {
            if (
              (state.user &&
                state.user.type === 'Serveur' &&
                cmd.afficher.serveur) ||
              cmd.afficher[`type_${state.user && state.user.id_type}`]
            ) {
              state.notificationCommandes.push(cmd);
            }
          }
          break;
        case 'delete':
          state.commandes = state.commandes.filter((elm) => elm.id != cmd.id);
          break;
      }

      dispatch('actstats', null, 1);
      break;
    case 'viderlist':
      if (action.payload && action.payload.id) {
        state.notificationCommandes = state.notificationCommandes.filter(
          (elm) => elm.id != action.payload.id
        );
      } else {
        state.notificationCommandes = [];
      }
      break;
    case 'syncLogout':
      state.user = null;
      break;
    case 'actstats':
      let ss = state.commandes.reduce((stats, cmd) => {
        return cmd.list.reduce((stats, item) => {
          stats[item.state] = stats[item.state] || 0;
          if (
            state.user &&
            (state.user.role != 'poste' ||
              item.id_typepreparateur == state.user.id_type)
          ) {
            stats[item.state] += 1;
          }
          return stats;
        }, stats);
      }, {});

      state.stats = Object.entries(ss).map(([key, value]) => ({ key, value }));

      if (state.user) {
        switch (state.user.role) {
          case 'serveur':
            state.nbralert = ss['Prêt'] || 0;
            break;
        }
      }

      if (state.data && state.data.tables) {
        state.data.tables = state.data.tables.map((table) => {
          table.cmd = state.commandes.find((cmd) => cmd.id_table == table.id);
          return table;
        });
      }

      state.activecommandes = state.commandes.filter((elm) => {
        return (
          state.user &&
          (elm.id_user == state.user.id ||
            state.user.role !== 'serveur' ||
            ((!elm.table || !elm.table.id) &&
              state.user.role == 'serveur' &&
              state.user.typeserveur == 'Pass'))
        );
      });

      state.notificationCommandes = state.notificationCommandes.filter((cmd) =>
        state.activecommandes.find((elm) => elm.id == cmd.id)
      );

      state.nbralertserveur = 0;
      if (state.user && state.user.role == 'serveur') {
        let ss1 = state.commandes.reduce((stats, cmd) => {
          if (
            state.user &&
            state.user.role == 'serveur' &&
            cmd.id_user == state.user.id &&
            cmd.type_user == 'Serveur'
          ) {
            return cmd.list.reduce((stats, item) => {
              stats[item.state] = stats[item.state] || 0;
              stats[item.state] += 1;
              return stats;
            }, stats);
          } else {
            return stats;
          }
        }, {});
        state.nbralertserveur = ss1['Prêt'] || 0;
      }

      break;
    case 'setPublicUserInfo':
      if (state.publicUser && action.payload) {
        state.publicUser = { ...state.publicUser, ...action.payload };
      }
      break;
    case 'setPublicUser':
      state.publicUserUpdated = true;
      state.publicUser = action.payload;
      if (state.publicUser) {
        state.api('getUserInfos', { user: state.publicUser }).then((rep) => {
          if (rep) {
            dispatch('setPublicUserInfo', rep);
          }
        });
      }
      break;
    default:
      break;
  }
  return { ...state };
}

String.prototype.getUrl = function () {
  if (store.getState && (store.getState() || {}).data.host)
    return (store.getState() || {}).data.host + this;
  return this;
};

const store = createStore(mainReducer);

const dispatch = (type, payload = null, delay = 0) => {
  console.log({ type, payload });
  if (delay) {
    setTimeout(() => {
      store.dispatch({ type, payload });
    }, delay);
  } else {
    store.dispatch({ type, payload });
  }
};

export default store;
